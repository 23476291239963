import React, { useMemo } from 'react';
import { useGetInterfaceStoreState } from '../../stores/InterfaceStore';
import ImportObjectRecordsModal from '../../../components/ImportObjectRecordsModal';
import { useGlobalStateStore } from '../../stores/GlobalStateStore';
import useEventCallback from '@unifyapps/hooks/useEventCallback';

function ImportObjectsFlow() {
  const importObjectRecords = useGlobalStateStore().use.flows.importObjectRecords();
  const { removePreBuiltFlow } = useGlobalStateStore().use.actions();
  const { getInterfaceStoreState } = useGetInterfaceStoreState();
  const pageEl = getInterfaceStoreState().interfacePageEl;
  const modalProps = useMemo(() => {
    return {
      container: pageEl,
      slotProps: {
        root: {
          className: 'absolute',
        },
        backdrop: {
          className: 'absolute',
        },
      },
    };
  }, [pageEl]);

  const removeEntityImport = useEventCallback(() => {
    removePreBuiltFlow('importObjectRecords');
  });

  const onError = useEventCallback(() => {
    removeEntityImport();
    importObjectRecords?.onError?.();
  });

  const onClose = useEventCallback(() => {
    removeEntityImport();
    importObjectRecords?.onClose?.();
  });

  if (!importObjectRecords?.objectId) return null;

  return (
    <React.Suspense fallback={null}>
      <ImportObjectRecordsModal
        additionalProperties={importObjectRecords.objectInputs}
        modalProps={modalProps}
        objectId={importObjectRecords.objectId}
        onClose={onClose}
        onError={onError}
        onSuccess={importObjectRecords.onSuccess}
      />
    </React.Suspense>
  );
}

export default ImportObjectsFlow;
