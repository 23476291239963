import useToggle from '@unifyapps/hooks/useToggle';
import { Tooltip } from '@unifyapps/ui/components/Tooltip';
import { Typography, type TypographyProps } from '@unifyapps/ui/components/Typography';
import { clsx } from 'clsx';
import type { PropsWithChildren, CSSProperties } from 'react';
import { useCallback, useRef } from 'react';
import type { TooltipProps } from '@unifyapps/ui/components/Tooltip/types';

export const getTruncateProps = (maxNumberOfLines: number, className?: string) => {
  return { maxNumberOfLines, className };
};

// Utility function to determine if content overflows
const isContentTruncated = (element: HTMLElement | null) => {
  if (!element) return false;
  return element.scrollHeight > element.clientHeight;
};

export default function TypographyWithTooltip({
  children,
  maxNumberOfLines = 1,
  className,
  tooltipClassName,
  tooltipStyle,
  enterDelay = 0,
  mode,
  placement = 'top',
  leaveDelay,
  titleNode,
  slotProps,
  ...typographyProps
}: PropsWithChildren<TypographyProps> & {
  enterDelay?: number;
  leaveDelay?: number;
  tooltipClassName?: string;
  tooltipStyle?: CSSProperties;
  placement?: TooltipProps['placement'];
  titleNode?: React.ReactNode;
  mode?: 'light' | 'dark';
  slotProps?: TooltipProps['slotProps'];
}) {
  const typographyRef = useRef<HTMLDivElement>(null);
  const [isTooltipVisible, { on: showTooltip, off: hideTooltip }] = useToggle(false);

  const truncateProps = getTruncateProps(maxNumberOfLines, className);

  // Function to determine tooltip visibility
  const updateTooltipVisibility = useCallback(() => {
    const element = typographyRef.current;
    if (element && maxNumberOfLines) {
      if (isContentTruncated(element)) {
        showTooltip();
      } else {
        hideTooltip();
      }
    }
  }, [maxNumberOfLines, showTooltip, hideTooltip]);

  return (
    <Tooltip
      className={tooltipClassName}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      mode={mode}
      onClose={hideTooltip}
      onOpen={updateTooltipVisibility}
      open={isTooltipVisible}
      placement={placement}
      slotProps={slotProps}
      style={tooltipStyle}
      title={children}
      titleNode={titleNode}
    >
      <Typography ref={typographyRef} {...typographyProps} {...truncateProps}>
        {children}
      </Typography>
    </Tooltip>
  );
}
