import type { ConditionEvaluationObject } from '@unifyapps/defs/types/conditionEvaluationObject';
import { useEffect } from 'react';
import { useReactiveComputeContext } from '../../../hooks/computeContext';
import { useComputeConditionEvaluationGetter } from '../../../hooks/useComputeConditionEvaluationGetter';
import { useDataSourceRecordStore } from '../../../stores/DataSourceRecordStore';
import DataSourceHelper from '../../../helper/DataSourceHelper';
import { DependencyFlowStatus, useGlobalStateStore } from '../../../stores/GlobalStateStore';
import DependencyFlowHelper from '../../../stores/GlobalStateStore/helpers/DependencyFlowHelper';
import useShowDisabledDataSourceSnackbar from './useShowDisabledDataSourceSnackbar';

export function useIsDataSourceDisabled() {
  const { context } = useReactiveComputeContext();
  const getComputedFilters = useComputeConditionEvaluationGetter();

  const getIsDisabled = ({ disabled }: { disabled?: ConditionEvaluationObject }) => {
    return getComputedFilters<{
      conditions?: boolean;
    }>(
      {
        conditions: disabled,
      },
      context,
    ).conditions;
  };

  return { getIsDisabled };
}

function useFinishDisabledAutomaticDS(automaticDataSourceIds: string[]) {
  const { getIsDisabled } = useIsDataSourceDisabled();
  const datasourceRecords = useDataSourceRecordStore().use.dataSources();
  const dataSourceDependencyFlow = useGlobalStateStore().use.dependencyFlow.dataSources();
  const {
    datasource: { addDependencyFlows },
  } = useGlobalStateStore().use.actions();
  const { showDisabledDSMessage } = useShowDisabledDataSourceSnackbar();

  const disabledDataSources = automaticDataSourceIds.filter((dataSourceId) => {
    const dataSource = getIsDisabled({
      disabled: DataSourceHelper.advancedOptions(datasourceRecords[dataSourceId]).queryDisabled,
    });
    return dataSource;
  });

  const nonFinishedDisabledDataSources = disabledDataSources.filter((dataSourceId) => {
    return dataSourceDependencyFlow[dataSourceId]?.status !== DependencyFlowStatus.Finished;
  });

  useEffect(() => {
    // if the ds was made disabled due to some run time condition, but it is not yet finished,
    // that means some parent might be waiting for this ds to finish, so it can go to Commit stage, that's why move
    // it by ourselves
    if (nonFinishedDisabledDataSources.length) {
      addDependencyFlows(
        DependencyFlowHelper.createFinishedDependencyFlow({
          ids: nonFinishedDisabledDataSources,
        }),
      );

      nonFinishedDisabledDataSources.forEach((dataSourceId) => {
        showDisabledDSMessage(dataSourceId);
      });
    }
  }, [addDependencyFlows, nonFinishedDisabledDataSources, showDisabledDSMessage]);

  return {
    disabledDataSources: disabledDataSources.reduce<Record<string, boolean>>((acc, id) => {
      acc[id] = true;
      return acc;
    }, {}),
  };
}

export default useFinishDisabledAutomaticDS;
