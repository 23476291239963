import { useTranslation } from '@unifyapps/i18n/client';
import ReactTable from '@unifyapps/table/components/ReactTable';
import type { MRT_Cell as Cell, MRT_TableInstance as TableInstance } from '@unifyapps/table/types';
import { openEditingCell } from '@unifyapps/table/utils/cell';
import type { EntityImportRequest } from '@unifyapps/network/generated/models/entityImportRequest';
import { getAggregateQueryKey } from '@unifyapps/network/generated/aggregation-rest-api/aggregation-rest-api';
import SvgUpload01 from '@unifyapps/icons/outline/Upload01';
import type { EntityImportFieldMapping } from '@unifyapps/network/generated/models/entityImportFieldMapping';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import type { AggregationMetadata } from '@unifyapps/network/generated/models/aggregationMetadata';
import { useEffect, useMemo, useState } from 'react';
import AsyncView from '../../AsyncView';
import type { ModalProps } from '../../Modal/Modal';
import Modal from '../../Modal';
import useMutationHelpers from '../../../hooks/useMutationHelpers';
import { useDataSourceWorkflowMutation } from '../../../no-code/components/DataSourceController/hooks/useDataSourceWorkflowMutation';
import useMapObjectRecordFieldsTable from './useTable';
import type { MapObjectRecordFieldsRowType } from './types';
import { useMappedFields } from './useMappedFields';
import { getInitialData } from './utils';

interface MapObjectRecordFieldsModalProps {
  fileHeaders?: string[];
  variables: { data: EntityImportRequest };
  objectId: string;
  onClose: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  modalProps?: Pick<ModalProps, 'container' | 'slotProps'>;
  additionalProperties?: { key: string; value: string }[];
}

interface ObjectMapperTableProps {
  objectId: string;
  metadata: AggregationMetadata | undefined;
  setData: React.Dispatch<React.SetStateAction<MapObjectRecordFieldsRowType[]>>;
  fieldMappingFromAPI: EntityImportFieldMapping[] | undefined;
  data?: MapObjectRecordFieldsRowType[];
}

const getRowId = (row: MapObjectRecordFieldsRowType) => row.fileField || '';

const muiTableBodyCellProps = ({
  cell,
  table,
}: {
  cell: Cell<MapObjectRecordFieldsRowType>;
  table: TableInstance<MapObjectRecordFieldsRowType>;
}) => ({
  onClick: () => {
    openEditingCell({ cell, table });
  },
});

type ImportRecordsInputsType = {
  object_type: string;
  fieldMappings: EntityImportFieldMapping[];
  additionalProperties?: {
    key: string;
    value: string;
  }[];
  source: string;
  fileType: string;
};

const storageByUnifyapps = 'storage_by_unifyapps';
const storageByUnifyappsImportRecords = 'storage_by_unifyapps_import_records';

function ObjectMapperTable(props: ObjectMapperTableProps) {
  const { metadata, data, setData, fieldMappingFromAPI } = props;

  const initiallyUsedFieldsInMapping = useMemo(() => {
    if (!fieldMappingFromAPI) return [];
    return fieldMappingFromAPI.map((field) => field.entityField ?? '');
  }, [fieldMappingFromAPI]);

  const { columns } = useMapObjectRecordFieldsTable({
    metadata,
    initiallyUsedFieldsInMapping,
    setData,
  });

  return (
    <ReactTable
      columns={columns}
      data={data ?? []}
      editDisplayMode="cell"
      enableEditing
      enableRowVirtualization={false}
      enableStickyHeader
      enableTopToolbar={false}
      getRowId={getRowId}
      layoutMode="grid"
      muiTableBodyCellProps={muiTableBodyCellProps}
    />
  );
}

function MapObjectRecordFieldsModal(props: MapObjectRecordFieldsModalProps) {
  const { t } = useTranslation(['objects']);
  const {
    fileHeaders,
    objectId,
    onClose,
    variables,
    modalProps,
    onError,
    onSuccess,
    additionalProperties,
  } = props;

  const { isPending, mutate } = useDataSourceWorkflowMutation();
  const { onShowSuccessSnackbar, onShowErrorSnackbar, onInvalidateQueries } = useMutationHelpers();
  const [data, setData] = useState<MapObjectRecordFieldsRowType[] | undefined>();

  const { fieldMappingFromAPI, isLoading, error, metadata } = useMappedFields({
    entityType: objectId,
    fileHeaders,
  });

  useEffect(() => {
    if (!isLoading) {
      const initialData = getInitialData({
        fileHeaders,
        fieldMappingFromAPI,
        metadata,
      });
      setData(initialData);
    }
  }, [isLoading, fieldMappingFromAPI, fileHeaders, metadata]);

  const onSubmit = useEventCallback(() => {
    const fieldMappings = data
      ?.map((row) => ({
        fileField: row.fileField,
        entityField: row.entityField?.name,
      }))
      .filter((row) => row.entityField);

    if (!fieldMappings?.length) {
      onShowErrorSnackbar({ title: t('objects:NoFieldsMapped') });
      return;
    }

    if (!variables.data.source || !variables.data.fileType) {
      onShowErrorSnackbar({ title: t('objects:InvalidFileType') });
      return;
    }

    const inputs: ImportRecordsInputsType = {
      object_type: objectId,
      fieldMappings,
      additionalProperties,
      source: variables.data.source,
      fileType: variables.data.fileType,
    };

    const context = {
      appName: storageByUnifyapps,
      resourceName: storageByUnifyappsImportRecords,
    };

    mutate(
      {
        data: {
          context,
          inputs,
        },
      },
      {
        onSuccess: (response) => {
          if ((response.response as { success?: 'true' }).success === 'true') {
            onShowSuccessSnackbar({
              title: t('objects:ImportedSuccessfully'),
              description: t('objects:ImportedSuccessfullyDescription'),
            });
            onInvalidateQueries([getAggregateQueryKey({})]);
            onClose();
            onSuccess?.();
          } else {
            onShowErrorSnackbar();
            onError?.();
          }
        },
        onError: () => {
          onShowErrorSnackbar();
          onError?.();
        },
      },
    );
  });

  return (
    <Modal open variant="md" {...modalProps}>
      <Modal.Header.Horizontal
        Icon={SvgUpload01}
        description={t('objects:MapFieldsDescription')}
        title={t('objects:MapFields')}
      />
      <Modal.Content.Base>
        <AsyncView data={data} error={error} isLoading={isLoading}>
          <ObjectMapperTable
            {...props}
            data={data}
            fieldMappingFromAPI={fieldMappingFromAPI}
            metadata={metadata}
            setData={setData}
          />
        </AsyncView>
      </Modal.Content.Base>
      <Modal.Footer.HorizontalRight
        cancelLabel={t('common:Actions.Cancel')}
        isPending={isPending}
        onCancel={onClose}
        onSubmit={onSubmit}
        submitLabel={t('common:Actions.Confirm')}
      />
    </Modal>
  );
}

export default MapObjectRecordFieldsModal;
