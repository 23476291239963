import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDataSourceRecordStore } from '../../../../../stores/DataSourceRecordStore';
import { useComputeConditionEvaluationGetter } from '../../../../../hooks/useComputeConditionEvaluationGetter';
import { useGetComputeContext } from '../../../../../hooks/computeContext';
import { useGlobalStateStore } from '../../../../../stores/GlobalStateStore';
import DependencyFlowHelper from '../../../../../stores/GlobalStateStore/helpers/DependencyFlowHelper';
import DataSourceHelper from '../../../../../helper/DataSourceHelper';
import useShowDisabledDataSourceSnackbar from '../../../hooks/useShowDisabledDataSourceSnackbar';

function useManualDataSourceTriggerIds() {
  const dsDependencyFlow = useGlobalStateStore().use.dependencyFlow.dataSources();
  const dsIntraDependencies = useGlobalStateStore().use.intraDependencies.dataSources();
  const dataSourceRecords = useDataSourceRecordStore().use.dataSources();
  const manuallyTriggeredDatasources = useGlobalStateStore().use.manuallyTriggeredDatasources();
  const manualDataSourceTriggerIds = Object.keys(manuallyTriggeredDatasources);
  const { getComputeContext } = useGetComputeContext();
  const context = getComputeContext();
  const getComputedFilters = useComputeConditionEvaluationGetter();
  const { removeManuallyTriggeredDataSources } = useGlobalStateStore().use.actions();
  const { showDisabledDSMessage } = useShowDisabledDataSourceSnackbar();

  const freeManualDataSourceTriggerIds = manualDataSourceTriggerIds.filter((key) => {
    const id = manuallyTriggeredDatasources[key].dataSourceId;
    return DependencyFlowHelper.areAllDependenciesFinished({
      dependencies: dsIntraDependencies?.[id],
      dependencyFlowInstance: dsDependencyFlow,
    });
  });

  const disabledKeys = useMemo(() => {
    const _ids = freeManualDataSourceTriggerIds.filter((key) => {
      const id = manuallyTriggeredDatasources[key].dataSourceId;
      const queryDisabled =
        DataSourceHelper.advancedOptions(dataSourceRecords[id]).queryDisabled ?? {};
      if (isEmpty(queryDisabled)) return false;

      return getComputedFilters(queryDisabled, context);
    });

    return new Set(_ids);
  }, [
    context,
    dataSourceRecords,
    freeManualDataSourceTriggerIds,
    getComputedFilters,
    manuallyTriggeredDatasources,
  ]);

  const enabledKeys = freeManualDataSourceTriggerIds.filter((key) => {
    return !disabledKeys.has(key);
  });

  useEffect(() => {
    if (disabledKeys.size) {
      // as manual ds is fire and forget, we remove the entry because it is not needed as at the time of firing the disablity was met
      removeManuallyTriggeredDataSources(Array.from(disabledKeys));
      disabledKeys.forEach((key) => {
        const dataSourceId = manuallyTriggeredDatasources[key].dataSourceId;
        showDisabledDSMessage(dataSourceId);
      });
    }
  }, [
    disabledKeys,
    manuallyTriggeredDatasources,
    removeManuallyTriggeredDataSources,
    showDisabledDSMessage,
  ]);

  return {
    triggerIds: enabledKeys,
    requests: manuallyTriggeredDatasources,
  };
}

export default useManualDataSourceTriggerIds;
