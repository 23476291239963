import { useCallback } from 'react';
import { useSnackbar } from '@unifyapps/ui/components/Snackbar';
import { useDataSourceRecordStore } from '../../../stores/DataSourceRecordStore';
import DataSourceHelper from '../../../helper/DataSourceHelper';

function useShowDisabledDataSourceSnackbar() {
  const { showSnackbar } = useSnackbar();
  const dataSourceRecords = useDataSourceRecordStore().use.dataSources();

  const showDisabledDSMessage = useCallback(
    (dataSourceId: string) => {
      const disabledQueryMessage = DataSourceHelper.advancedOptions(
        dataSourceRecords[dataSourceId],
      ).queryDisabledMessage;
      if (disabledQueryMessage) {
        showSnackbar({
          title: disabledQueryMessage,
          color: 'warning',
        });
      }
    },
    [dataSourceRecords, showSnackbar],
  );

  return { showDisabledDSMessage };
}

export default useShowDisabledDataSourceSnackbar;
