import _forEach from 'lodash/forEach';
import isEqual from 'react-fast-compare';
import _reduce from 'lodash/reduce';
import { memo } from 'react';
import _pick from 'lodash/pick';
import type { PageFunctionState } from '@unifyapps/defs/types/pageFunction';
import _debounce from 'lodash/debounce';
import _throttle from 'lodash/throttle';
import { useReactiveComputeContext } from '../../../hooks/computeContext';
import type { EntityDependency } from '../../../stores/GlobalStateStore';
import { useGlobalStateStore } from '../../../stores/GlobalStateStore';
import { EMPTY_ARRAY } from '../../../../consts/empty';
import { evaluateResult } from '../../../utils/evaluateExpression';
import useDynamicEffect from '../../../hooks/useDynamicEffect';
import { removeDataBindingBraces } from '../../../utils/dynamicBindings';
import { useTriggerAutomaticDataSources } from '../../DataSourceController/hooks/useTriggerAutomaticDataSources';

function evaluatePageFunction({
  context,
  functionBody,
  setPageFunctionState,
  pageFunctionState,
}: {
  context: Record<string, unknown>;
  functionBody: string;
  setPageFunctionState: (id: string, state: PageFunctionState) => void;
  pageFunctionState: PageFunctionState;
}) {
  if (!pageFunctionState) {
    return;
  }

  try {
    const keys = Object.keys(context);
    const values: unknown[] = Object.values(context);
    const funcBody = removeDataBindingBraces(functionBody);

    // escape newline characters to avoid "Invalid or unexpected token" error
    // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func -- needed
    const evalFunction = new Function(...keys, funcBody);

    const value = evalFunction(...values) as unknown;

    if (!isEqual(value, pageFunctionState.value)) {
      setPageFunctionState(pageFunctionState.id, {
        ...pageFunctionState,
        value,
        error: undefined,
      });
    }
  } catch (e: unknown) {
    setPageFunctionState(pageFunctionState.id, {
      ...pageFunctionState,
      error: _pick(e, ['message', 'stack']) as { message: string; stack: string },
    });
  }
}

const throttledEvaluatePageFunction = _throttle(evaluatePageFunction, 300);

function PageFunctionExecutor({ functionBody, id }: { functionBody: string; id: string }) {
  const { context } = useReactiveComputeContext();
  const pageFunctionState = useGlobalStateStore().use.pageFunction.details(id);

  const { isSuspended } = useTriggerAutomaticDataSources({
    dataSourceIds: pageFunctionState?.dataSourceIds,
    nonBlockingDataSourceIds: [],
  });

  const pageFunctionDependencies =
    useGlobalStateStore().use.entityDependencies.dependencies(id) ??
    (EMPTY_ARRAY as EntityDependency[]);

  const dependentEntities = _reduce(
    pageFunctionDependencies,
    (acc, dependency) => {
      const result = evaluateResult<unknown>({
        context,
        resolvedStringToEvaluate: dependency.property,
        shouldEvaluateResult: true,
      });

      acc.push(result);

      return acc;
    },
    [] as unknown[],
  );

  const { setPageFunctionState } = useGlobalStateStore().use.actions();

  useDynamicEffect(() => {
    if (isSuspended) {
      return;
    }

    throttledEvaluatePageFunction({
      context,
      functionBody,
      setPageFunctionState,
      pageFunctionState,
    });
  }, [functionBody, isSuspended, ...dependentEntities]);

  return null;
}

export default memo(PageFunctionExecutor);
