import type { ApiEndpointInputsType } from '@unifyapps/defs/types/dataSource';
import { useApiEndpointQuery } from '../../../../../hooks/useApiEndpointQuery';
import type { QueryRequestResult } from '../../../../stores/GlobalStateStore';

function useApiEndpointSource({
  payload,
}: {
  payload: ApiEndpointInputsType['httpRequest'];
}): QueryRequestResult {
  const { isLoading, isFetching, error: fetchError, data, queryKey } = useApiEndpointQuery(payload);

  return {
    error: fetchError,
    isLoading,
    isFetching,
    data,
    queryKey,
  };
}

export default useApiEndpointSource;
