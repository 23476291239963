import { useEffect, useRef } from 'react';

function sameDeps(prevDeps: unknown[], nextDeps: unknown[]): boolean {
  return (
    prevDeps.length === nextDeps.length &&
    prevDeps.every((value, index) => Object.is(value, nextDeps[index]))
  );
}

function useDynamicEffect(effectCallback: () => void, dependencies: unknown[]) {
  const depsRef = useRef<unknown[]>();

  if (depsRef.current === undefined || !sameDeps(depsRef.current, dependencies)) {
    depsRef.current = dependencies;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps -- effectCallback is a constant
  useEffect(effectCallback, [depsRef.current]);
}

export default useDynamicEffect;
