import type { ApiEndpointInputsType } from '@unifyapps/defs/types/dataSource';
import { useQuery } from '@unifyapps/network/react-query';
import type {
  UseQueryResult,
  QueryFunction,
  QueryKey,
  UseQueryOptions,
} from '@unifyapps/network/react-query';
import type { ErrorType } from '@unifyapps/network/utils/fetch';
import { executeFetch } from '@unifyapps/network/utils/fetch';

const InputLanguageContentType = {
  json: 'application/json',
  xml: 'application/xml',
  text: 'text/plain',
  javascript: 'application/javascript',
  html: 'text/html',
};

const getHTTPBody = (body: ApiEndpointInputsType['httpRequest']['body']) => {
  return body?.contentType === 'none' || !body?.inputLanguage ? undefined : body.input;
};

const getContentType = (body: ApiEndpointInputsType['httpRequest']['body']) => {
  return body?.contentType === 'raw'
    ? {
        'Content-Type': InputLanguageContentType[body.inputLanguage],
      }
    : undefined;
};

export const apiEndpointQuery = (data: ApiEndpointInputsType['httpRequest']) => {
  return executeFetch<unknown>({
    url: data.url,
    method: data.method,
    params: data.queryParams,
    headers: {
      ...getContentType(data.body),
      ...data.headers,
    },
    data: getHTTPBody(data.body),
  });
};

export const geApiEndpointQueryKey = (data: ApiEndpointInputsType['httpRequest']) => {
  return [data.url, data.body, data.headers, data.queryParams] as const;
};

export const getApiEndpointQueryOptions = <
  TData = Awaited<ReturnType<typeof apiEndpointQuery>>,
  TError = ErrorType<unknown>,
>(
  apiEndpointQueryRequest: ApiEndpointInputsType['httpRequest'],
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof apiEndpointQuery>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? geApiEndpointQueryKey(apiEndpointQueryRequest);

  const queryFn: QueryFunction = () => apiEndpointQuery(apiEndpointQueryRequest);

  return { queryKey, queryFn, ...queryOptions };
};

export type QueryResult = NonNullable<Awaited<ReturnType<typeof apiEndpointQuery>>>;
export type QueryError = ErrorType<unknown>;

export const useApiEndpointQuery = <
  TData = Awaited<ReturnType<typeof apiEndpointQuery>>,
  TError = ErrorType<unknown>,
>(
  apiEndpointQueryRequest: ApiEndpointInputsType['httpRequest'],
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof apiEndpointQuery>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getApiEndpointQueryOptions(apiEndpointQueryRequest, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
