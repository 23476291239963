import type { ActionContext } from '@unifyapps/defs/hooks/useBlockEvents';

export const getActionContext = (
  computedContext: Record<string, unknown>,
  actionContext: ActionContext | undefined,
) => {
  return {
    ...computedContext,
    ...(typeof actionContext === 'function' ? actionContext(computedContext) : actionContext),
  };
};
