import _forEach from 'lodash/forEach';
import type { ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import { useRegistryContext } from '../../../RegistryProvider';
import { InterfaceModes } from '../../../../stores/InterfaceStore';

export default function usePreloadBlocks({ mode }: { mode: InterfaceModes }) {
  const { registry } = useRegistryContext();

  // in builder mode, preload all blocks
  if (mode === InterfaceModes.BUILDER) {
    const allComponentTypes = registry.getBlockStateDefinitionsTypes();
    _forEach(allComponentTypes, (componentType) => {
      registry.preloadBlock(componentType as ComponentTypeUnionType);
    });
  } else {
    // _forEach(blocks, (block) => {
    //   registry.preloadBlock(block.component.componentType, block);
    // });
  }
}
