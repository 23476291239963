import { useCallback } from 'react';
import { invariant } from 'ts-invariant';
import type { OnActionType } from '../../../../components/ActionsProvider/context';
import useControlBlockMethod from '../controlBlockMethod/useControlBlockMethod';

type ModalActionPayload = {
  modalId?: string;
  operation: 'show' | 'hide';
};

function useModalActions() {
  const { onCallBlockMethod } = useControlBlockMethod();

  const onControlModal: OnActionType = useCallback(
    async ({ action, actionContext }) => {
      const payload = action.payload as ModalActionPayload;
      const blockId = payload.modalId;
      const operation = payload.operation;

      invariant(blockId, 'Modal id is required');

      switch (operation) {
        case 'show':
          await onCallBlockMethod({
            blockId,
            methodName: 'showModal',
            action,
            actionContext,
          });
          break;
        case 'hide':
          await onCallBlockMethod({
            blockId,
            methodName: 'hideModal',
            action,
            actionContext,
          });
          break;
        default:
          break;
      }
      return Promise.resolve();
    },
    [onCallBlockMethod],
  );

  return { onControlModal };
}

export default useModalActions;
