import type {
  ComputedPageVariableState,
  SetVariableBooleanTypePayload,
} from '@unifyapps/defs/types/pageVariable';

const convertToBoolean = (value: unknown) => {
  if (typeof value === 'string') {
    const lowerCaseValue = value.toLowerCase();
    if (lowerCaseValue === 'false') return false;
    if (lowerCaseValue === 'true') return true;
  }
  return Boolean(value);
};

export function getNextPageVariableStateForBoolean({
  variableState,
  operationDetails,
}: {
  variableState: ComputedPageVariableState<unknown>;
  operationDetails: {
    operation: SetVariableBooleanTypePayload['operationDetails']['operation'];
    value?: unknown;
  };
}) {
  switch (operationDetails.operation) {
    case 'SET': {
      return {
        ...variableState,
        value: convertToBoolean(operationDetails.value),
      };
    }
    case 'TOGGLE': {
      return {
        ...variableState,
        value: !convertToBoolean(variableState.value),
      };
    }
  }
}
