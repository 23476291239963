import type { ReactNode } from 'react';
import type BlocksRegistry from './BlocksRegistry';
import { RegistryProviderContext } from './context';

function RegistryProvider({
  children,
  registry,
}: {
  children: ReactNode;
  registry: BlocksRegistry;
}) {
  return (
    <RegistryProviderContext.Provider value={registry}>{children}</RegistryProviderContext.Provider>
  );
}

export default RegistryProvider;
