import type { ExecuteWorkflowNodeRequest } from '@unifyapps/network/generated/models/executeWorkflowNodeRequest';
import { useMemo } from 'react';

function containsFile(obj: Record<string, unknown>): boolean {
  for (const key in obj) {
    if (obj[key] instanceof File) {
      return true;
    }
  }
  return false;
}

function useDSWorkflowNodePayload({ payload }: { payload: ExecuteWorkflowNodeRequest }) {
  return useMemo(() => {
    if (payload.context?.resourceName !== 'callables_call_automation') return payload;

    const newPayload = {
      ...payload,
      inputs: { ...payload.inputs, parameters: { ...(payload.inputs?.parameters ?? {}) } },
    } as ExecuteWorkflowNodeRequest;

    const parameters = newPayload.inputs?.parameters as Record<string, unknown>;

    const isFilePresent = containsFile(parameters);

    if (!isFilePresent) return payload;

    const formData = new FormData();

    // now file is present in parameters and we want to send the payload against "request" key after clearing the files from parameters
    // for each key that is a file, we add "parameters.<key>" to the formData

    for (const key in parameters) {
      if (parameters[key] instanceof File) {
        formData.append(`parameters.${key}`, parameters[key], parameters[key].name);
        parameters[key] = undefined;
      }
    }

    // add the rest of the parameters to the formData
    formData.append('request', JSON.stringify(newPayload));

    return formData;
  }, [payload]);
}

export default useDSWorkflowNodePayload;
