export type Serializable =
  | string
  | number
  | boolean
  | null
  | undefined
  | Serializable[]
  | { [key: string]: Serializable };

export const getStringifiedCopyPath = (obj: Serializable): string => {
  if (typeof obj === 'string') {
    return `'${obj}'`;
  }
  if (typeof obj === 'number' || typeof obj === 'boolean') {
    return obj.toString();
  }
  if (Array.isArray(obj)) {
    return `[${obj.map(getStringifiedCopyPath).join(', ')}]`;
  }
  if (typeof obj === 'object' && obj !== null) {
    const props = Object.entries(obj)
      .map(([key, value]) => `${key}:${getStringifiedCopyPath(value)}`)
      .join(', ');
    return `{${props}}`;
  }
  return '';
};
