import React, { useCallback, useMemo } from 'react';
import { SnackbarProvider } from '@unifyapps/ui/components/Snackbar';
import type { EmitEventType } from '@unifyapps/defs/hooks/useBlockEvents';
import { parseAsString, useQueryState } from 'nuqs';
import type { BlockId } from '@unifyapps/defs/types/block';
import _noop from 'lodash/noop';
import useScreen from '@unifyapps/hooks/useScreen';
import { useTranslation } from '@unifyapps/i18n/client';
import { ComputeContextProvider } from '../../context/ComputeContextProvider/ComputeContextProvider';
import { DrawerProvider } from '../../../components/Drawer';
import { useGetDeviceDetailsFromLocalStorage } from '../../hooks/local-storage/useDeviceDetailsFromLocalStorage';
import type { WithPartial } from '../../../types';
import { getBaseDeviceOfThePage } from '../../stores/InterfaceStore/utils/block';
import { useInteractiveModeFromLocalStorage } from '../../hooks/local-storage/useInteractiveModeFromLocalStorage';
import { UppyProvider } from '../../../components/UppyProvider';
import { DataSourceRecordStoreProvider } from '../../stores/DataSourceRecordStore/DataSourceRecordStoreProvider';
import {
  InterfaceClient,
  InterfaceModes,
  InterfaceStoreProvider,
} from '../../stores/InterfaceStore';
import type { DeviceDetailsType } from '../../stores/GlobalStateStore';
import { GlobalStateStoreProvider } from '../../stores/GlobalStateStore';
import DataSourceController from '../DataSourceController';
import useGlobalBlockActions from '../../hooks/useGlobalBlockActions';
import RegistryProvider from '../RegistryProvider';
import ActionsProvider from '../ActionsProvider';
import { SideEffectsInStore } from '../SideEffectsInStore';
import { PreBuiltFlows } from '../PreBuiltFlows';
import AsyncView from '../../../components/AsyncView';
import InterfacePageHelper from '../../helper/InterfacePage';
import { NotificationsActionsProvider } from '../NotificationsActionsProvider';
import { useInjectHTML } from '../../hooks/useInjectHTML';
import userStore from '../../../auth/userStore';
import type { OnActionType } from '../ActionsProvider/context';
import { PageFunctionController } from '../PageFunctionController';
import InterfaceEntitiesMethodsProvider from '../InterfaceEntitiesMethodsProvider';
import { ResetBlockRuntimeStateProvider } from '../BlockRuntimeStateProvider';
import type { InterfaceResourceProviderProps } from './types';
import useInterfaceResourceProvider from './useInterfaceResourceProvider';
import { useStoreRefs } from './hooks/useStoreRefs';

function InterfaceActionProvider({
  children,
  emitPageEvent,
  disableActions,
}: {
  children: React.ReactNode;
  emitPageEvent?: EmitEventType;
  disableActions?: boolean;
}) {
  const { onAction } = useGlobalBlockActions({
    emitPageEvent,
  });

  return (
    <ActionsProvider
      isGlobalActionsProvider
      onAction={disableActions ? (_noop as OnActionType) : onAction}
    >
      {children}
    </ActionsProvider>
  );
}

// We assume the interface is being used in desktop if the device is not provided
function BaseInterfaceResourceProvider(props: InterfaceResourceProviderProps) {
  const {
    page,
    registry,
    children,
    onOutputChange,
    mode,
    deviceDetails,
    dataSources,
    emitPageEvent,
    onChangeActiveBlockId,
    isInteractiveModeEnabled,
    interfaceResourceProviderRef,
  } = props;

  useTranslation('blocks');

  const { device } = deviceDetails;
  const { dataSourceRecordStoreInitialValue, finalPageInputs, interfaceStoreInitialValue } =
    useInterfaceResourceProvider(props);

  const { globalStateStoreRef, interfaceStoreRef } = useStoreRefs({
    interfaceResourceProviderRef,
  });

  const blocks = useMemo(() => {
    return {
      ...InterfacePageHelper.getBaseDeviceBlocks(page),
      ...InterfacePageHelper.getDeviceBlocks(page, device),
    };
  }, [device, page]);

  return (
    <RegistryProvider registry={registry}>
      <DataSourceRecordStoreProvider initialValue={dataSourceRecordStoreInitialValue}>
        <GlobalStateStoreProvider
          blocks={blocks}
          client={props.client}
          dataSources={dataSources}
          deviceDetails={deviceDetails}
          globalStateStoreRef={globalStateStoreRef}
          interfaceId={interfaceStoreInitialValue.interfaceRecord.id}
          mode={mode}
          pageFunctions={page.properties.pageFunctions}
          pageInputs={finalPageInputs}
          pageVariables={page.properties.pageVariables}
        >
          <InterfaceStoreProvider
            interfaceStoreRef={interfaceStoreRef}
            onChangeActiveBlockId={onChangeActiveBlockId}
            value={interfaceStoreInitialValue}
          >
            <ResetBlockRuntimeStateProvider>
              <InterfaceEntitiesMethodsProvider emitPageEvent={emitPageEvent}>
                <ComputeContextProvider>
                  <SideEffectsInStore onOutputChange={onOutputChange}>
                    <InterfaceActionProvider
                      disableActions={mode === InterfaceModes.PREVIEW && !isInteractiveModeEnabled}
                      emitPageEvent={emitPageEvent}
                    >
                      <DataSourceController>
                        <PageFunctionController>
                          <SnackbarProvider>
                            <UppyProvider>
                              <PreBuiltFlows>
                                <NotificationsActionsProvider>
                                  <DrawerProvider>{children}</DrawerProvider>
                                </NotificationsActionsProvider>
                              </PreBuiltFlows>
                            </UppyProvider>
                          </SnackbarProvider>
                        </PageFunctionController>
                      </DataSourceController>
                    </InterfaceActionProvider>
                  </SideEffectsInStore>
                </ComputeContextProvider>
              </InterfaceEntitiesMethodsProvider>
            </ResetBlockRuntimeStateProvider>
          </InterfaceStoreProvider>
        </GlobalStateStoreProvider>
      </DataSourceRecordStoreProvider>
    </RegistryProvider>
  );
}

type ModeInterfaceResourceProviderProps = WithPartial<
  InterfaceResourceProviderProps,
  'deviceDetails'
> & {
  disableInteractiveMode?: boolean;
};

function RunnerInterfaceResourceProvider(props: ModeInterfaceResourceProviderProps) {
  const { screen } = useScreen();
  const deviceDetailsData = useMemo(() => ({ device: screen }), [screen]);

  return (
    <BaseInterfaceResourceProvider
      {...props}
      deviceDetails={deviceDetailsData}
      isInteractiveModeEnabled
    />
  );
}

function BuilderAndPreviewInterfaceResourceProvider(props: ModeInterfaceResourceProviderProps) {
  const { interfaceRecord, mode, disableInteractiveMode } = props;
  const currentUserDetails = userStore.use.currentUserDetails();
  const [initialActiveBlockId, setBlockIdInParams] = useQueryState<BlockId>(
    'blockId',
    parseAsString,
  );

  const { deviceDetails, loading: deviceLoading } = useGetDeviceDetailsFromLocalStorage({
    interfaceRecordId: interfaceRecord.id,
    baseDevice: getBaseDeviceOfThePage(interfaceRecord),
    enabled: !props.deviceDetails,
  });

  const { value: _isInteractiveModeEnabled, loading } = useInteractiveModeFromLocalStorage({
    interfaceRecordId: interfaceRecord.id,
    enabled: mode === InterfaceModes.BUILDER,
  });

  // In runner mode (matrix) we inject custom code in root layout directly
  useInjectHTML(interfaceRecord.properties.customCode?.header, currentUserDetails, 'head');
  useInjectHTML(interfaceRecord.properties.customCode?.footer, currentUserDetails, 'body');

  const isInteractiveModeEnabled = useMemo(() => {
    // If interactive mode is disabled, we should not enable it in preview mode
    //this is done for /overview path
    if (disableInteractiveMode) {
      return false;
    }

    return mode === InterfaceModes.PREVIEW || _isInteractiveModeEnabled;
  }, [_isInteractiveModeEnabled, disableInteractiveMode, mode]);

  const onChangeActiveBlockId = useCallback(
    (blockId: string) => {
      void setBlockIdInParams(blockId);
    },
    [setBlockIdInParams],
  );

  const renderData = useCallback(
    (_deviceDetails: DeviceDetailsType) => (
      <BaseInterfaceResourceProvider
        {...props}
        deviceDetails={_deviceDetails}
        initialActiveBlockId={initialActiveBlockId}
        isInteractiveModeEnabled={isInteractiveModeEnabled}
        onChangeActiveBlockId={onChangeActiveBlockId}
      />
    ),
    [initialActiveBlockId, isInteractiveModeEnabled, onChangeActiveBlockId, props],
  );

  return (
    <AsyncView
      data={props.deviceDetails || deviceDetails}
      isLoading={loading || deviceLoading}
      renderData={renderData}
      withSuspense={false}
    />
  );
}

export function InterfaceResourceProvider(
  props: WithPartial<InterfaceResourceProviderProps, 'mode' | 'deviceDetails' | 'client'> & {
    disableInteractiveMode?: boolean;
  },
) {
  const {
    page,
    mode = InterfaceModes.RUNNER,
    client = mode === InterfaceModes.RUNNER
      ? InterfaceClient.UA_MATRIX
      : InterfaceClient.UA_PLATFORM,
  } = props;
  const currentUserDetails = userStore.use.currentUserDetails();

  useInjectHTML(page.properties.customCode?.header, currentUserDetails, 'head');
  useInjectHTML(page.properties.customCode?.footer, currentUserDetails, 'body');

  return mode === InterfaceModes.RUNNER ? (
    <RunnerInterfaceResourceProvider {...props} client={client} mode={mode} />
  ) : (
    <BuilderAndPreviewInterfaceResourceProvider {...props} client={client} mode={mode} />
  );
}

export default InterfaceResourceProvider;
