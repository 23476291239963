/* eslint-disable @typescript-eslint/no-explicit-any -- can't escape any */
import type { AggregationResponse } from '@unifyapps/network/generated/models/aggregationResponse';
import type { BatchResponseAggregationResponse } from '@unifyapps/network/generated/models/batchResponseAggregationResponse';
import type { Hits } from '@unifyapps/network/generated/models/hits';
import {
  useAggregate,
  useBatchAggregate,
} from '@unifyapps/network/generated/aggregation-rest-api/aggregation-rest-api';
import { useLookup } from '@unifyapps/network/generated/lookup-rest-api/lookup-rest-api';
import type { QueryKey, UseQueryResult } from '@unifyapps/network/react-query';
import type { OperationId } from '@unifyapps/defs/types/query';
import type { LookupResponse } from '@unifyapps/network/generated/models/lookupResponse';
import { useExecuteWorkflowNode } from '@unifyapps/network/generated/workflow-rest-api/workflow-rest-api';
import _identity from 'lodash/identity';
import { useAuthLookup } from '../../../hooks/useAuthLookup';

const EMPTY_OBJECT: Record<string, any> = {};
const EMPTY_ARRAY: unknown[] = [];

export const OPERATION_ID_TO_LOOKUP_HOOK: Partial<
  Record<OperationId, (...args: any[]) => UseQueryResult<unknown, unknown> & { queryKey: QueryKey }>
> = {
  aggregate: useAggregate,
  batchAggregate: useBatchAggregate,
  lookup: useLookup,
  auth: useAuthLookup,
  executeWorkflowNode: useExecuteWorkflowNode,
};

export const OPERATION_ID_TO_RESPONSE_ADAPTER: Record<
  OperationId,
  (response: any) => {
    objects: unknown[];
  }
> = {
  aggregate: (res: any) => {
    const { objects } = (res ?? EMPTY_OBJECT) as AggregationResponse;
    return {
      objects: Array.isArray(objects) ? objects : EMPTY_ARRAY,
    };
  },
  batchAggregate: (res: any) => {
    const { responses } = (res ?? EMPTY_OBJECT) as BatchResponseAggregationResponse;
    const objects = responses?.flatMap((response) => response.objects) ?? EMPTY_ARRAY;
    return {
      objects,
    };
  },
  lookup: (res: any) => {
    const { response } = (res ?? EMPTY_OBJECT) as LookupResponse;
    const { objects } = (response ?? EMPTY_OBJECT) as Hits;

    return {
      objects: Array.isArray(objects) ? objects : EMPTY_ARRAY,
    };
  },
  auth: (res: any) => {
    const { response } = (res ?? EMPTY_OBJECT) as LookupResponse;
    const { objects } = (response ?? EMPTY_OBJECT) as Hits;
    return {
      objects: Array.isArray(objects) ? objects : EMPTY_ARRAY,
    };
  },
  executeWorkflowNode: (res) => ({
    objects: (res as unknown[] | undefined) ?? EMPTY_ARRAY,
  }),
};
