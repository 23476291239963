import React from 'react';
import { ImportObjectsFlow } from '../ImportObjectsFlow';

function PreBuiltFlows({ children }: { children: React.ReactNode }) {
  return (
    <>
      <ImportObjectsFlow />
      {children}
    </>
  );
}

export default PreBuiltFlows;
