import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import { useQuery } from '@unifyapps/network/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryResult,
  UseQueryOptions,
  QueryClient,
} from '@unifyapps/network/react-query';
import type { ErrorType } from '@unifyapps/network/utils/fetch';
import { executeFetch } from '@unifyapps/network/utils/fetch';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- same as in the generated hooks
type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getAuthInterface = (
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<InterfaceEntity>(
    {
      url: `/auth/interface`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getAuthInterfaceQueryKey = () => {
  return [`/auth/interface`] as const;
};

export const getAuthInterfaceQueryOptions = <
  TData = Awaited<ReturnType<typeof getAuthInterface>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthInterface>>, TError, TData>>;
  request?: SecondParameter<typeof executeFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuthInterfaceQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuthInterface>>> = ({ signal }) =>
    getAuthInterface(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 5000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAuthInterface>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAuthInterfaceQueryResult = NonNullable<Awaited<ReturnType<typeof getAuthInterface>>>;
export type GetAuthInterfaceQueryError = ErrorType<unknown>;

export const useGetAuthInterface = <
  TData = Awaited<ReturnType<typeof getAuthInterface>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthInterface>>, TError, TData>>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthInterfaceQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetAuthInterface = async <
  TData = Awaited<ReturnType<typeof getAuthInterface>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAuthInterface>>, TError, TData>>;
  },
): Promise<QueryClient> => {
  const queryOptions = getAuthInterfaceQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};
