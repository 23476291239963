import type { LookupRequest } from '@unifyapps/network/generated/models/lookupRequest';
import type { LookupResponse } from '@unifyapps/network/generated/models/lookupResponse';
import { useQuery } from '@unifyapps/network/react-query';
import type {
  UseQueryResult,
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  QueryClient,
} from '@unifyapps/network/react-query';
import type { ErrorType } from '@unifyapps/network/utils/fetch';
import { executeFetch } from '@unifyapps/network/utils/fetch';

export const authLookup = (lookupRequest: LookupRequest) => {
  return executeFetch<LookupResponse>({
    url: `/auth/lookup`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: lookupRequest,
  });
};

export const getAuthLookupQueryKey = (lookupRequest: LookupRequest) => {
  return [`/auth/lookup`, lookupRequest] as const;
};

export const prefetchAuthLookup = async <
  TData = Awaited<ReturnType<typeof authLookup>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  lookupRequest: LookupRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authLookup>>, TError, TData>>;
  },
): Promise<QueryClient> => {
  const queryOptions = getAuthLookupQueryOptions(lookupRequest, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getAuthLookupQueryOptions = <
  TData = Awaited<ReturnType<typeof authLookup>>,
  TError = ErrorType<unknown>,
>(
  lookupRequest: LookupRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authLookup>>, TError, TData>>;
  },
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuthLookupQueryKey(lookupRequest);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof authLookup>>> = () =>
    authLookup(lookupRequest);

  return { queryKey, queryFn, ...queryOptions };
};

export type LookupQueryResult = NonNullable<Awaited<ReturnType<typeof authLookup>>>;
export type LookupQueryError = ErrorType<unknown>;

export const useAuthLookup = <
  TData = Awaited<ReturnType<typeof authLookup>>,
  TError = ErrorType<unknown>,
>(
  lookupRequest: LookupRequest,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof authLookup>>, TError, TData>>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuthLookupQueryOptions(lookupRequest, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
