import { useCallback, useMemo } from 'react';
import { useSet } from 'react-use';
import { useTranslation } from '@unifyapps/i18n/client';
import type { MRT_ColumnDef as ColumDef, CustomColumnDef } from '@unifyapps/table/types';
import SvgArrowRight from '@unifyapps/icons/outline/ArrowRight';
import { Typography } from '@unifyapps/ui/components/Typography';
import type { AutocompleteProps } from '@unifyapps/ui/components/Autocomplete';
import type { AggregationField } from '@unifyapps/network/generated/models/aggregationField';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import type { AggregationMetadata } from '@unifyapps/network/generated/models/aggregationMetadata';
import EntityFieldCell from './EntityFieldCell';
import type { MapObjectRecordFieldsRowType } from './types';

interface UseMapObjectRecordFieldsTableProps {
  metadata: AggregationMetadata | undefined;
  setData: React.Dispatch<React.SetStateAction<MapObjectRecordFieldsRowType[]>>;
  initiallyUsedFieldsInMapping?: string[];
}

const useMapObjectRecordFieldsTable = (props: UseMapObjectRecordFieldsTableProps) => {
  const { t } = useTranslation(['objects']);
  const { metadata, initiallyUsedFieldsInMapping, setData } = props;
  const [_, usedFields] = useSet<string>(new Set<string>(initiallyUsedFieldsInMapping ?? []));

  const onUpdateMapping = useEventCallback(
    (entityField: AggregationField | null, fileField: string) => {
      setData((prevData) => {
        return prevData.map((row) => {
          if (row.fileField === fileField) {
            entityField && usedFields.add(entityField.name || ''); // add the field to the set
            row.entityField && usedFields.remove(row.entityField.name || ''); // remove the old field from the set
            return { ...row, entityField };
          }
          return row;
        });
      });
    },
  );

  // using a callback, since aggregationFields are dependent on rowData - we don't want the current selected field to get filtered out
  const getAggregationFields = useCallback(
    (value?: AggregationField | null) => {
      if (!metadata?.aggregationFields?.length) {
        return [];
      }
      // filter out the fields that are not updatable or already mapped to other fields
      return metadata.aggregationFields.filter(
        (field) =>
          field.updatable && (field.name === value?.name || !usedFields.has(field.name || '')),
      );
    },
    [metadata?.aggregationFields, usedFields],
  );

  const columns = useMemo<
    (ColumDef<MapObjectRecordFieldsRowType> & CustomColumnDef<MapObjectRecordFieldsRowType>)[]
  >(() => {
    return [
      {
        header: t('objects:Headers'),
        accessorKey: 'fileField',
        enableEditing: false,
        muiTableBodyCellProps: { className: 'text-sm text-primary' },
      },
      {
        header: '',
        size: 40,
        grow: 0,
        accessorKey: 'arrow',
        enableEditing: false,
        Cell: () => <SvgArrowRight className="text-fg-senary size-6" />,
      },
      {
        header: t('objects:FieldToMap'),
        accessorKey: 'entityField',
        enableEditing: true,
        editVariant: 'select',
        uiEditSelectProps: ({ row }) =>
          ({
            getOptionLabel: (option) => option.displayName || '',
            getOptionKey: (option) => option.name || '',
            options: getAggregationFields(row.getValue('entityField')),
            isOptionEqualToValue: (option, value) => option.name === value.name,
            renderOptionLabel: ({ option }) => <EntityFieldCell field={option} />,
            onChange: (_event, value) => {
              onUpdateMapping(value, row.getValue('fileField'));
            },
            multiple: false,
            disableClearable: false,
          }) satisfies AutocompleteProps<AggregationField, false, false, false>,
        Cell: ({ cell }) => {
          const cellValue = cell.getValue();

          if (cellValue) {
            return <EntityFieldCell field={cellValue} />;
          }

          return (
            <Typography color="text-placeholder" variant="text-sm">
              {t('objects:SelectField')}
            </Typography>
          );
        },
      },
    ];
  }, [getAggregationFields, onUpdateMapping, t]);

  return { columns };
};

export default useMapObjectRecordFieldsTable;
