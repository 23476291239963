import { DEFAULT_MOBILE_VARIANT, DeviceVariantType } from '@unifyapps/defs/types/deviceVariant';
import type { DeviceDetailsType } from '../../../stores/GlobalStateStore';
import { useInterfaceLocalStorage } from '../useInterfaceLocalStorage';

export const getDeviceDetailsLocalStorageKey = (interfaceRecordId: string) =>
  `${interfaceRecordId}.deviceVariant`;

export const useDeviceDetailsFromLocalStorage = ({
  interfaceRecordId,
  enabled,
}: {
  interfaceRecordId: string;
  enabled?: boolean;
}) => {
  const { value, loading, setValue } = useInterfaceLocalStorage<DeviceDetailsType | undefined>({
    key: getDeviceDetailsLocalStorageKey(interfaceRecordId),
    enabled,
  });

  return {
    value,
    loading,
    setValue,
  };
};

export function useGetDeviceDetailsFromLocalStorage({
  interfaceRecordId,
  baseDevice,
  enabled,
}: {
  interfaceRecordId: string;
  baseDevice: DeviceVariantType;
  enabled?: boolean;
}) {
  const defaultDeviceDetails = {
    device: baseDevice,
    ...(baseDevice === DeviceVariantType.MOBILE ? { mobileVariant: DEFAULT_MOBILE_VARIANT } : {}),
  };

  const { value: deviceDetails = defaultDeviceDetails, loading } = useDeviceDetailsFromLocalStorage(
    {
      interfaceRecordId,
      enabled,
    },
  );

  return { deviceDetails, loading };
}
