import { useMemo } from 'react';
import type { BlockType } from '@unifyapps/defs/types/block';
import type { DataSource } from '@unifyapps/defs/types/dataSource';
import { useInterfaceStore } from '../../../stores/InterfaceStore';
import { getEntityTypeDataSourceFromBlock } from '../utils/getEntityTypeDataSourceFromBlock';

export const getEntityTypeDataSources = (
  blocks: Record<string, BlockType>,
  activeInterfacePageId: string,
) => {
  const entityTypeDataSources: Record<string, DataSource & { id: string }> = {};

  Object.values(blocks).forEach((block) => {
    const dataSource = getEntityTypeDataSourceFromBlock(block, activeInterfacePageId);

    if (!dataSource) {
      return;
    }

    entityTypeDataSources[dataSource.id] = dataSource;
  });

  return { entityTypeDataSources };
};

export function useGetEntityTypeDataSources() {
  const activeInterfacePageId = useInterfaceStore().use.activeInterfacePageId();
  const blocks = useInterfaceStore().use.blocks.fromBaseDevice();

  return useMemo(() => {
    return getEntityTypeDataSources(blocks ?? {}, activeInterfacePageId);
  }, [activeInterfacePageId, blocks]);
}
