/**
 * Generated by orval 🍺
 * Do not edit manually.
 * api API
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { ExecuteWorkflowNodeMultipartFormDataBody } from '../models/executeWorkflowNodeMultipartFormDataBody';
import type { ExecuteWorkflowNodeRequest } from '../models/executeWorkflowNodeRequest';
import type { ExecuteWorkflowNodeResponse } from '../models/executeWorkflowNodeResponse';
import type { ExecuteWorkflowNodes200 } from '../models/executeWorkflowNodes200';
import type { ExecuteWorkflowRequest } from '../models/executeWorkflowRequest';
import type { TriggerWorkflow200 } from '../models/triggerWorkflow200';
import { executeFetch } from '../../utils/fetch/fetch';
import type { ErrorType } from '../../utils/fetch/fetch';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const cancelExecution = (
  executionId: string,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<void>(
    {
      url: `/api/workflow/cancel/execution/${encodeURIComponent(String(executionId))}`,
      method: 'POST',
    },
    options,
  );
};

export const getCancelExecutionMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelExecution>>,
    TError,
    { executionId: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof cancelExecution>>,
  TError,
  { executionId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelExecution>>,
    { executionId: string }
  > = (props) => {
    const { executionId } = props ?? {};

    return cancelExecution(executionId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelExecutionMutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelExecution>>
>;

export type CancelExecutionMutationError = ErrorType<unknown>;

export const useCancelExecution = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelExecution>>,
    TError,
    { executionId: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof cancelExecution>>,
  TError,
  { executionId: string },
  TContext
> => {
  const mutationOptions = getCancelExecutionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const executeWorkflowNode = (
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<ExecuteWorkflowNodeResponse>(
    {
      url: `/api/workflow/execute/node`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: executeWorkflowNodeRequest,
    },
    options,
  );
};

export const getExecuteWorkflowNodeQueryKey = (
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest,
) => {
  return [`/api/workflow/execute/node`, executeWorkflowNodeRequest] as const;
};

export const getExecuteWorkflowNodeQueryOptions = <
  TData = Awaited<ReturnType<typeof executeWorkflowNode>>,
  TError = ErrorType<unknown>,
>(
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof executeWorkflowNode>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getExecuteWorkflowNodeQueryKey(executeWorkflowNodeRequest);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof executeWorkflowNode>>> = () =>
    executeWorkflowNode(executeWorkflowNodeRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof executeWorkflowNode>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ExecuteWorkflowNodeQueryResult = NonNullable<
  Awaited<ReturnType<typeof executeWorkflowNode>>
>;
export type ExecuteWorkflowNodeQueryError = ErrorType<unknown>;

export const useExecuteWorkflowNode = <
  TData = Awaited<ReturnType<typeof executeWorkflowNode>>,
  TError = ErrorType<unknown>,
>(
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof executeWorkflowNode>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getExecuteWorkflowNodeQueryOptions(executeWorkflowNodeRequest, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchExecuteWorkflowNode = async <
  TData = Awaited<ReturnType<typeof executeWorkflowNode>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof executeWorkflowNode>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getExecuteWorkflowNodeQueryOptions(executeWorkflowNodeRequest, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const executeWorkflowNodeMultipartFormData = (
  executeWorkflowNodeMultipartFormDataBody: ExecuteWorkflowNodeMultipartFormDataBody,
  options?: SecondParameter<typeof executeFetch>,
) => {
  const formData = new FormData();
  if (executeWorkflowNodeMultipartFormDataBody.request !== undefined) {
    formData.append('request', JSON.stringify(executeWorkflowNodeMultipartFormDataBody.request));
  }

  return executeFetch<ExecuteWorkflowNodeResponse>(
    {
      url: `/api/workflow/execute/node/multipart`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  );
};

export const getExecuteWorkflowNodeMultipartFormDataMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof executeWorkflowNodeMultipartFormData>>,
    TError,
    { data: ExecuteWorkflowNodeMultipartFormDataBody },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof executeWorkflowNodeMultipartFormData>>,
  TError,
  { data: ExecuteWorkflowNodeMultipartFormDataBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof executeWorkflowNodeMultipartFormData>>,
    { data: ExecuteWorkflowNodeMultipartFormDataBody }
  > = (props) => {
    const { data } = props ?? {};

    return executeWorkflowNodeMultipartFormData(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExecuteWorkflowNodeMultipartFormDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof executeWorkflowNodeMultipartFormData>>
>;
export type ExecuteWorkflowNodeMultipartFormDataMutationBody =
  ExecuteWorkflowNodeMultipartFormDataBody;
export type ExecuteWorkflowNodeMultipartFormDataMutationError = ErrorType<unknown>;

export const useExecuteWorkflowNodeMultipartFormData = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof executeWorkflowNodeMultipartFormData>>,
    TError,
    { data: ExecuteWorkflowNodeMultipartFormDataBody },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof executeWorkflowNodeMultipartFormData>>,
  TError,
  { data: ExecuteWorkflowNodeMultipartFormDataBody },
  TContext
> => {
  const mutationOptions = getExecuteWorkflowNodeMultipartFormDataMutationOptions(options);

  return useMutation(mutationOptions);
};
export const executeWorkflowNodes = (
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest[],
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<ExecuteWorkflowNodes200>(
    {
      url: `/api/workflow/execute/nodes`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: executeWorkflowNodeRequest,
    },
    options,
  );
};

export const getExecuteWorkflowNodesQueryKey = (
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest[],
) => {
  return [`/api/workflow/execute/nodes`, executeWorkflowNodeRequest] as const;
};

export const getExecuteWorkflowNodesQueryOptions = <
  TData = Awaited<ReturnType<typeof executeWorkflowNodes>>,
  TError = ErrorType<unknown>,
>(
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest[],
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof executeWorkflowNodes>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getExecuteWorkflowNodesQueryKey(executeWorkflowNodeRequest);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof executeWorkflowNodes>>> = () =>
    executeWorkflowNodes(executeWorkflowNodeRequest, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof executeWorkflowNodes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ExecuteWorkflowNodesQueryResult = NonNullable<
  Awaited<ReturnType<typeof executeWorkflowNodes>>
>;
export type ExecuteWorkflowNodesQueryError = ErrorType<unknown>;

export const useExecuteWorkflowNodes = <
  TData = Awaited<ReturnType<typeof executeWorkflowNodes>>,
  TError = ErrorType<unknown>,
>(
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest[],
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof executeWorkflowNodes>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getExecuteWorkflowNodesQueryOptions(executeWorkflowNodeRequest, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchExecuteWorkflowNodes = async <
  TData = Awaited<ReturnType<typeof executeWorkflowNodes>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest[],
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof executeWorkflowNodes>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getExecuteWorkflowNodesQueryOptions(executeWorkflowNodeRequest, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const triggerWorkflow = (
  executeWorkflowRequest: ExecuteWorkflowRequest,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<TriggerWorkflow200>(
    {
      url: `/api/workflow/trigger`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: executeWorkflowRequest,
    },
    options,
  );
};

export const getTriggerWorkflowMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof triggerWorkflow>>,
    TError,
    { data: ExecuteWorkflowRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof triggerWorkflow>>,
  TError,
  { data: ExecuteWorkflowRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof triggerWorkflow>>,
    { data: ExecuteWorkflowRequest }
  > = (props) => {
    const { data } = props ?? {};

    return triggerWorkflow(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type TriggerWorkflowMutationResult = NonNullable<
  Awaited<ReturnType<typeof triggerWorkflow>>
>;
export type TriggerWorkflowMutationBody = ExecuteWorkflowRequest;
export type TriggerWorkflowMutationError = ErrorType<unknown>;

export const useTriggerWorkflow = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof triggerWorkflow>>,
    TError,
    { data: ExecuteWorkflowRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof triggerWorkflow>>,
  TError,
  { data: ExecuteWorkflowRequest },
  TContext
> => {
  const mutationOptions = getTriggerWorkflowMutationOptions(options);

  return useMutation(mutationOptions);
};
