import { useCallback } from 'react';
import { useLocalStorage as useReactUseLocalStorage } from 'react-use';

type SetValue<T> = (value: T) => void;
type GetValue<T> = () => Promise<T | undefined>;
type RemoveKey = () => Promise<void>;

type ParserOptions<T> =
  | {
      raw: true;
    }
  | {
      raw: false;
      serializer: (value: T) => string;
      deserializer: (value: string) => T;
    };

//global-object for iframe and local storage disabled use case
const localStorageInMemoryMap = new Map();

//this is made asynchronous because in future this will be replaced with
//user preference service so for easy transition we are making it async
//and not directly using react-use useLocalStorage
export const useLocalStorage = <T>(
  key: string,
  initialValue?: T,
  options?: ParserOptions<T>,
): [SetValue<T>, GetValue<T>, RemoveKey] => {
  const [_value, _setValue, _removeKey] = useReactUseLocalStorage(key, initialValue, options);

  const setValue = useCallback(
    async (value: T): Promise<void> => {
      await Promise.resolve();
      _setValue(value);
      localStorageInMemoryMap.set(key, value);
    },
    [_setValue, key],
  );

  const getValue = useCallback(async () => {
    await Promise.resolve();
    return _value ?? (localStorageInMemoryMap.get(key) as T);
  }, [_value, key]);

  const remove = useCallback(async () => {
    await Promise.resolve();
    _removeKey();
    localStorageInMemoryMap.delete(key);
  }, [_removeKey, key]);

  return [setValue, getValue, remove];
};
