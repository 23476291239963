import type { AggregationMetadata } from '@unifyapps/network/generated/models/aggregationMetadata';
import type { EntityImportFieldMapping } from '@unifyapps/network/generated/models/entityImportFieldMapping';
import { keyBy } from 'lodash';
import type { AggregationField } from '@unifyapps/network/generated/models/aggregationField';
import type { MapObjectRecordFieldsRowType } from './types';

export function getInitialData({
  fileHeaders,
  fieldMappingFromAPI,
  metadata,
}: {
  fileHeaders?: string[];
  fieldMappingFromAPI?: EntityImportFieldMapping[];
  metadata?: AggregationMetadata;
}): MapObjectRecordFieldsRowType[] {
  if (!fileHeaders) return [];

  const mappedDataByFileField = keyBy(fieldMappingFromAPI, 'fileField') as Partial<
    Record<string, EntityImportFieldMapping>
  >;
  const aggregationFieldsByName = keyBy(metadata?.aggregationFields, 'name') as Partial<
    Record<string, AggregationField>
  >;

  return fileHeaders.map((fileField) => {
    const mappedRowEntityField = mappedDataByFileField[fileField]?.entityField;

    const aggregationMetadataField = aggregationFieldsByName[mappedRowEntityField ?? ''];

    return { fileField, entityField: aggregationMetadataField };
  });
}
