import type { ApiEndpointInputsType, DataSource } from '@unifyapps/defs/types/dataSource';
import isEqual from 'react-fast-compare';
import { useEffect } from 'react';
import { useGlobalStateStore } from '../../../../stores/GlobalStateStore';
import { useExecuteDataSourceCallbacks } from '../../hooks/useExecuteDataSourceCallbacks';
import type { QueryNode } from '../../types';
import useApiEndpointSource from './useApiEndpointSource';

function ApiEndpointQueryNode({
  id,
  apiEndpoint,
  callbacks,
  onQueryCompletion,
  requestId,
}: {
  id: string;
  requestId: string;
  apiEndpoint: ApiEndpointInputsType;
  callbacks: DataSource['callbacks'];
  onQueryCompletion: QueryNode['onQueryCompletion'];
}) {
  const queryResult = useApiEndpointSource({
    payload: apiEndpoint.httpRequest,
  });
  const { setDataSourceState } = useGlobalStateStore().use.actions();
  const getGlobalStateStoreState = useGlobalStateStore().getState;

  useExecuteDataSourceCallbacks({
    callbacks,
    onQueryCompletion,
    id,
    requestId,
    queryResult: {
      isLoading: queryResult.isLoading,
      isFetching: queryResult.isFetching,
      error: queryResult.error,
    },
  });

  useEffect(() => {
    const prevDataSourceValue = getGlobalStateStoreState().dataSources[id];
    if (isEqual(queryResult, prevDataSourceValue)) return;
    setDataSourceState(id, queryResult);
  }, [getGlobalStateStoreState, id, queryResult, setDataSourceState]);

  return null;
}

export default ApiEndpointQueryNode;
