import type { ExecuteWorkflowNodeRequest } from '@unifyapps/network/generated/models/executeWorkflowNodeRequest';
import type { DebugParams } from '../types';

enum ExecutePlatformByUAOperation {
  CreateConnectionWithInput = 'createConnectionWithInput',
  FetchApplicationByName = 'fetchApplicationByName',
  FetchJavaArchivePodLogs = 'fetchJavaArchivePodLogs',
  FetchEntityPermissions = 'fetchEntityPermissions',
  FetchUserEntityPermissions = 'fetchUserEntityPermissions',
}

const PLATFORM_BY_UA_OPERATION_ID_VS_QUERY_FN = {
  [ExecutePlatformByUAOperation.CreateConnectionWithInput]: () =>
    import('./operationQueryFn/createConnectionWithInput'),
  [ExecutePlatformByUAOperation.FetchApplicationByName]: () =>
    import('./operationQueryFn/fetchApplicationByName'),
  [ExecutePlatformByUAOperation.FetchJavaArchivePodLogs]: () =>
    import('./operationQueryFn/fetchJavaArchivePodLogs'),
  [ExecutePlatformByUAOperation.FetchEntityPermissions]: () =>
    import('./operationQueryFn/fetchEntityPermissions'),
  [ExecutePlatformByUAOperation.FetchUserEntityPermissions]: () =>
    import('./operationQueryFn/fetchUserEntityPermissions'),
};

export async function executePlatformByUAOperation(
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest,
  debugParams: DebugParams,
) {
  const operationId = executeWorkflowNodeRequest.inputs?.operationId as
    | ExecutePlatformByUAOperation
    | undefined;

  if (!operationId) {
    console.error('Operation ID is not defined');
    return Promise.reject(new Error('Operation ID is not defined'));
  }

  const moduleLoader = PLATFORM_BY_UA_OPERATION_ID_VS_QUERY_FN[operationId];

  try {
    const { default: operationFunction } = await moduleLoader();
    return operationFunction(executeWorkflowNodeRequest, debugParams);
  } catch (error) {
    console.error(`Error loading or executing operation ${operationId}:`, error);
    throw error;
  }
}
