import { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from '@unifyapps/i18n/client';
import { useParseColumnNames } from '@unifyapps/network/generated/entity-rest-api/entity-rest-api';
import useToggle from '@unifyapps/hooks/useToggle';
import type { ModalProps } from '../Modal/Modal';
import useMutationHelpers from '../../hooks/useMutationHelpers';
import UploadRecordsFileModal from './UploadRecordsFileModal';
import MapObjectRecordFieldsModal from './MapObjectRecordFieldsModal';

interface ImportObjectRecordsModalProps {
  objectId: string;
  onClose: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  modalProps?: Pick<ModalProps, 'container' | 'slotProps'>;
  additionalProperties?: {
    key: string;
    value: string;
  }[];
}

const getEntityImportRequest = (file: { s3Multipart: { key: string }; extension: string }) => {
  const fileType = file.extension.toUpperCase();
  const source = file.s3Multipart.key;

  switch (fileType) {
    case 'CSV':
    case 'XLS':
    case 'XLSX':
      return { fileType, source };
    default:
      return null;
  }
};

function ImportObjectRecordsModal(props: ImportObjectRecordsModalProps) {
  const { objectId, onClose, additionalProperties, modalProps, onError, onSuccess } = props;
  const { t } = useTranslation(['objects']);
  const [showMapping, { on }] = useToggle(false);
  const { onShowErrorSnackbar } = useMutationHelpers();
  const { isPending, data, mutate, variables } = useParseColumnNames();

  const onGetColumnNames = useCallback(
    (file: { s3Multipart: { key: string }; extension: string }) => {
      const mutateData = getEntityImportRequest(file);

      if (!mutateData) {
        onShowErrorSnackbar({ title: t('objects:InvalidFileType') });
        return;
      }

      mutate(
        { data: mutateData },
        {
          onSuccess: (columns) => {
            if (isEmpty(columns)) {
              onShowErrorSnackbar({ title: t('objects:NoColumnsFound') });
              return;
            }
            on();
          },
          onError: () => {
            onShowErrorSnackbar();
          },
        },
      );
    },
    [mutate, on, onShowErrorSnackbar, t],
  );

  if (showMapping && variables) {
    return (
      <MapObjectRecordFieldsModal
        additionalProperties={additionalProperties}
        fileHeaders={data}
        modalProps={modalProps}
        objectId={objectId}
        onClose={onClose}
        onError={onError}
        onSuccess={onSuccess}
        variables={variables}
      />
    );
  }

  return (
    <UploadRecordsFileModal
      isPending={isPending}
      modalProps={modalProps}
      onClose={onClose}
      onSubmit={onGetColumnNames}
    />
  );
}

export default ImportObjectRecordsModal;
