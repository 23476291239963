import { useEffect, useMemo } from 'react';
import DependencyFlowHelper from '../../../stores/GlobalStateStore/helpers/DependencyFlowHelper';
import { useGlobalStateStore } from '../../../stores/GlobalStateStore/GlobalStateStoreProvider';

function useCommitReadyDataSources() {
  const { datasource } = useGlobalStateStore().use.actions();
  const dsDependencyFlow = useGlobalStateStore().use.dependencyFlow.dataSources();
  const dsIntraDependencies = useGlobalStateStore().use.intraDependencies.dataSources();

  const dsIdsWithFinishedDependents = useMemo(() => {
    // Only staging data source are filtered, as they are the only ones that can be committed
    const stagedDataSources = Object.keys(dsDependencyFlow).filter((key) =>
      DependencyFlowHelper.isDependencyStaging(dsDependencyFlow, key),
    );

    return stagedDataSources.filter((id) =>
      // Check if all dependencies are finished
      DependencyFlowHelper.areAllDependenciesFinished({
        dependencies: dsIntraDependencies?.[id],
        dependencyFlowInstance: dsDependencyFlow,
      }),
    );
  }, [dsDependencyFlow, dsIntraDependencies]);

  useEffect(() => {
    // these ids are ready to be committed
    if (dsIdsWithFinishedDependents.length) {
      datasource.addDependencyFlows(
        DependencyFlowHelper.createCommitDependencyFlow({
          ids: dsIdsWithFinishedDependents,
        }),
      );
    }
  }, [datasource, dsIdsWithFinishedDependents]);
}

export default useCommitReadyDataSources;
