import type { BlockType } from '@unifyapps/defs/types/block';
import { UAResources } from '@unifyapps/defs/types/resources';
import { getSelectedDataSourcePath } from '../../../../../utils/getSelectedDataSourcePath';
import {
  getBlockContentDataSource,
  getDataSourceHasAggregationMetadata,
} from '../../../../utils/dataSourceMetadata';
import { type DataSourceRecordStoreState } from '../../../../stores/DataSourceRecordStore';

export function getDataSourcesForAggregationMetadata(
  block: BlockType,
  dataSources: DataSourceRecordStoreState['dataSources'],
) {
  if (
    block.component.componentType === 'Filters' ||
    block.component.componentType === 'CategoryFilter'
  ) {
    return block.component.content.dataSourceIds;
  } else if (block.component.componentType === 'Table') {
    const isFilterSourcePresent = Boolean(
      block.component.content.addOns?.topToolbar?.aggregationMetadataSource,
    );
    const { dataSource, dataSourceId } = getBlockContentDataSource(block, dataSources);
    const hasAggregationMetadata = getDataSourceHasAggregationMetadata({
      dataSource,
      dataSourceId,
    });

    // when the user has mapped filter source in filter addon, we only want to fetch metadata for that source
    if (isFilterSourcePresent) {
      const { dataSourceId: filterSourceDataSourceId } = getSelectedDataSourcePath({
        data: block.component.content.addOns?.topToolbar?.aggregationMetadataSource,
      });
      // this dataSourceId could be one of AGGREGATE_METADATA_DATA_SOURCE_RESOURCE_NAME
      return filterSourceDataSourceId ? [filterSourceDataSourceId] : [];
      // if the main data source has aggregation metadata, we want to fetch metadata for that source
    } else if (hasAggregationMetadata) {
      return dataSourceId ? [dataSourceId] : [];
    }

    return [];
  }
}

// these kind of resourcename would be used in Filter block or as a filter source in Table block
export const AGGREGATE_METADATA_DATA_SOURCE_RESOURCE_NAME = new Set<string>([
  UAResources.AnalyticsByUAAggregateMetadata,
  UAResources.StorageByUAAggregateMetadata,
  UAResources.CallablesCallAutomation,
]);
