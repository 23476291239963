import { useCallback, useMemo } from 'react';
import SvgUploadCloud02 from '@unifyapps/icons/outline/UploadCloud02';
import { useTranslation } from '@unifyapps/i18n/client';
import { Typography } from '@unifyapps/ui/components/Typography';
import { useUnmountEffect } from '@react-hookz/web';
import Modal from '../Modal';
import { UploadCard } from '../UploadCard';
import FileUpload from '../FileUpload';
import type { UploadedFile } from '../../hooks/useUppy';
import { useUppy } from '../../hooks/useUppy';
import type { ModalProps } from '../Modal/Modal';

interface UploadRecordsFileModalProps {
  isPending: boolean;
  onClose: () => void;
  onSubmit: (file: { s3Multipart: { key: string }; extension: string }) => void;
  modalProps?: Pick<ModalProps, 'container' | 'slotProps'>;
}

const referenceId = 'import-records';

function UploadRecordsFileModal(props: UploadRecordsFileModalProps) {
  const { t } = useTranslation(['objects']);
  const { isPending, onClose, onSubmit: onSubmitProp, modalProps } = props;
  const { uppy, files: _files, removeFileById, clearAll } = useUppy({ referenceId });
  const files = useMemo(() => Object.values(_files), [_files]);

  const file = files[0] as UploadedFile | undefined;
  const disabled = !file?.url;

  const onSubmit = useCallback(() => {
    if (!file?.url) {
      return;
    }
    onSubmitProp(file as unknown as { s3Multipart: { key: string }; extension: string });
  }, [file, onSubmitProp]);

  useUnmountEffect(() => {
    // clean up files when modal is unmounted
    clearAll();
  });

  return (
    <Modal open variant="md" {...modalProps}>
      <Modal.Header.Horizontal
        Icon={SvgUploadCloud02}
        description={t('objects:ImportDataDescription')}
        title={t('objects:ImportData')}
      />
      <Modal.Content.Base className="gap-y-sm flex flex-col">
        <Typography color="text-secondary" variant="text-sm" weight="medium">
          {t('objects:UploadFileTitle')}
        </Typography>
        {file ? (
          <FileUpload.UploadStatus files={files} onRemoveFile={removeFileById} />
        ) : (
          <UploadCard accept=".csv, .xls, .xlsx" referenceId={referenceId} uppy={uppy}>
            <FileUpload.Uploader
              description={{
                label: t('objects:UploadFileTypes'),
              }}
            />
          </UploadCard>
        )}
        <Typography color="text-tertiary" variant="text-xs" weight="regular">
          {t('objects:UploadFileHelp')}
        </Typography>
      </Modal.Content.Base>
      <Modal.Footer.HorizontalRight
        cancelLabel={t('common:Actions.Cancel')}
        disabled={disabled}
        isPending={isPending}
        onCancel={onClose}
        onSubmit={onSubmit}
        submitLabel={t('objects:ReviewData')}
      />
    </Modal>
  );
}

export default UploadRecordsFileModal;
