import { EventTypeEnum } from '@unifyapps/defs/types/event';
import type { Event } from '@unifyapps/defs/types/event';
import type { EmitActionParams } from '@unifyapps/defs/hooks/useBlockAction';
import type { Notification as NotificationType } from '@unifyapps/network/generated/models/notification';

export function getEventsToTrigger({
  events,
  actionContext,
  eventType,
}: {
  events: Event[];
  eventType: string;
  actionContext?: EmitActionParams['actionContext'];
}) {
  switch (eventType) {
    case EventTypeEnum.OnReceiveNotification: {
      return events.filter(
        (event) =>
          event.payload?.notificationType &&
          event.payload.notificationType ===
            (actionContext as { notification?: NotificationType } | undefined)?.notification?.id,
      );
    }
    default: {
      return events;
    }
  }
}
