import type { ExecuteWorkflowNodeRequest } from '@unifyapps/network/generated/models/executeWorkflowNodeRequest';
import createMemo from 'react-use/lib/factory/createMemo'; // dont change/minimize this import, required full path for react-native
import { getExecuteWorkflowNodeQueryKey as originalGetExecuteWorkflowNodeQueryKey } from '@unifyapps/network/generated/workflow-rest-api/workflow-rest-api';
import { executeFetch } from '@unifyapps/network/utils/fetch';
import type { ExecuteWorkflowNodeResponse } from '@unifyapps/network/generated/models/executeWorkflowNodeResponse';
import type { DebugParams } from './types';

export enum ExecutePlatformByUAOperation {
  CreateConnectionWithInput = 'createConnectionWithInput',
}

const getExecuteWorkflowNodeMultipartQueryKey = (executeWorkflowNodeRequest: FormData) => {
  return ['/api/workflow/execute/node/multipart', executeWorkflowNodeRequest] as const;
};

const getExecuteWorkflowAuthNodeQueryKey = (
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest,
) => {
  return ['/auth/workflow/execute/node', executeWorkflowNodeRequest] as const;
};

const getExecuteWorkflowNodeApiUpdatePasswordQueryKey = (
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest,
) => {
  return ['/api/user/update-password', executeWorkflowNodeRequest] as const;
};

const queryKeyMap: {
  [key in 'formdata' | 'auth' | 'api' | 'api-update-password']: (
    executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest | FormData,
  ) => readonly [string, ExecuteWorkflowNodeRequest | FormData];
} = {
  formdata: getExecuteWorkflowNodeMultipartQueryKey,
  auth: getExecuteWorkflowAuthNodeQueryKey,
  api: originalGetExecuteWorkflowNodeQueryKey,
  'api-update-password': getExecuteWorkflowNodeApiUpdatePasswordQueryKey,
};

function getExecuteWorkflowNodeQueryKey(
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest | FormData,
  debugParams: DebugParams,
  additionalParams?: Record<string, unknown>,
  queryKeyType: 'formdata' | 'auth' | 'api-update-password' | 'api' = 'api',
) {
  const originalQueryKey = queryKeyMap[queryKeyType](executeWorkflowNodeRequest);

  return [
    `${originalQueryKey[0]}?=${JSON.stringify(debugParams)}`, // This is the only line that differs from the original function, which contains the url
    ...originalQueryKey.slice(1),
    additionalParams,
  ] as const;
}

export const useExecuteWorkflowNodeQueryKeyMemo = createMemo(getExecuteWorkflowNodeQueryKey);

export function executeMultiPartFormDataWorkflowNode(
  executeWorkflowNodeRequest: FormData,
  debugParams: DebugParams,
) {
  const params = new URLSearchParams(debugParams as Record<string, string>);

  return executeFetch<ExecuteWorkflowNodeResponse>({
    url: `/api/workflow/execute/node/multipart?${params.toString()}`,
    method: 'POST',
    data: executeWorkflowNodeRequest,
  });
}

export function executeWorkflowNode(
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest,
  debugParams: DebugParams,
) {
  const params = new URLSearchParams(debugParams as Record<string, string>);

  return executeFetch<ExecuteWorkflowNodeResponse>({
    url: `/api/workflow/execute/node?${params.toString()}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: executeWorkflowNodeRequest,
  });
}

export function executeAuthWorkflowNode(
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest,
  debugParams: DebugParams,
) {
  const params = new URLSearchParams(debugParams as Record<string, string>);

  return executeFetch<ExecuteWorkflowNodeResponse>({
    url: `/auth/workflow/execute/node?${params.toString()}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: executeWorkflowNodeRequest,
  });
}

export function executeUpdatePasswordWorkflowNode(
  executeWorkflowNodeRequest: ExecuteWorkflowNodeRequest,
  debugParams: DebugParams,
) {
  const params = new URLSearchParams(debugParams as Record<string, string>);

  return executeFetch<ExecuteWorkflowNodeResponse>({
    url: `/api/user/update-password?${params.toString()}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: executeWorkflowNodeRequest,
  });
}
