import type { EmitEventType } from '@unifyapps/defs/hooks/useBlockEvents';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import type { OnActionType } from '../../components/ActionsProvider/context';
import { useCopyToClipboardAction } from './actions/copyToClipboard';
import { useNavigate } from './actions/navigate';
import { useLogin } from './actions/login';
import { useEvalJS } from './actions/evalJS';
import { useControlBlockMethod } from './actions/controlBlockMethod';
import { useShowNotification } from './actions/showNotifications';
import { useSetPageVariable } from './actions/setPageVariable';
import { useControlDataSource } from './actions/controlDataSource';
import { useControlModalActions } from './actions/controlModalActions';
import { useControlDrawerActions } from './actions/controlDrawerActions';
import { useNavigateToPage } from './actions/navigateToPage';
import { useFormActions } from './actions/submitForm';
import { useImportObjects } from './actions/importObjects';
import { useEmitPageEvent } from './actions/emitPageEvent';
import { useSetInterfaceSessionStorage } from './actions/setInterfaceSessionStorage';
import { useExecuteScript } from './actions/executeScript';

export default function useGlobalBlockActions({
  emitPageEvent,
}: {
  emitPageEvent?: EmitEventType;
}) {
  const { navigate, back } = useNavigate();
  const { login } = useLogin();
  const { onEvalJS } = useEvalJS();
  const { onShowNotification } = useShowNotification();
  const { onControlBlockMethod } = useControlBlockMethod();
  const { onSetPageVariable } = useSetPageVariable();
  const { onControlDataSource } = useControlDataSource();
  const { onControlModal } = useControlModalActions();
  const { onControlDrawer } = useControlDrawerActions();
  const { onSubmitForm } = useFormActions();
  const { navigateToPage } = useNavigateToPage();
  const { importObjects } = useImportObjects();
  const { onSetInterfaceSessionStorage } = useSetInterfaceSessionStorage();
  const { onEmitPageEvent } = useEmitPageEvent({
    emitPageEvent,
  });

  const { onExecuteScript } = useExecuteScript();
  const { handleCopyAction } = useCopyToClipboardAction();

  const onAction: OnActionType = useEventCallback<OnActionType>((args) => {
    const actionType = args.action.actionType;

    switch (actionType) {
      case 'navigate':
        return navigate(args);
      case 'navigateBack':
        return back(args);
      case 'login':
        return login(args);
      case 'emitPageEvent':
        return onEmitPageEvent(args);
      case 'controlBlockMethod':
        return onControlBlockMethod(args);
      case 'evalJS':
        return onEvalJS(args);
      case 'showNotification':
        return onShowNotification(args);
      case 'setPageVariable':
        return onSetPageVariable(args);
      case 'setInterfaceSessionStorage':
        return onSetInterfaceSessionStorage(args);
      case 'controlDataSource':
        return onControlDataSource(args);
      case 'controlModal':
        return onControlModal(args);
      case 'controlDrawer':
        return onControlDrawer(args);
      case 'navigateToPage':
        return navigateToPage(args);
      case 'submitForm':
        return onSubmitForm(args);
      case 'importObjects':
        return importObjects(args);
      case 'executeScript':
        return onExecuteScript(args);
      case 'copyToClipboard':
        return handleCopyAction(args);
      default:
        return Promise.resolve(false);
    }
  });

  return { onAction };
}
