import type { AutomationInputsType, NodeContextType } from '@unifyapps/defs/types/dataSource';
import { useEffect } from 'react';
import _pick from 'lodash/pick';
import isEqual from 'react-fast-compare';
import { useExecuteDataSourceCallbacks } from '../../hooks/useExecuteDataSourceCallbacks';
import { useGenerateQueryOptionsFromAdvancedOptions } from '../../hooks/useGenerateQueryOptionsFromAdvancedOptions';
import type { DataSourceWorkflowNodeProps } from '../../types';
import { KEYS_TO_MATCH } from '../../consts';
import { useComputeDataSourcePayload } from '../../hooks/useComputeDataSourcePayload';
import useDataSourceWorkflowNode from './useDataSourceWorkflowNode';
import { useAddSystemParameters } from './useAddSystemParameters';

function DataSourceWorkflowNode({
  id,
  requestId,
  inputs,
  context,
  lookupRequests,
  onQueryCompletion,
  name,
  callbacks,
  advancedOptions,
  setDataSourceState,
  getCurrentQueryResult,
  refetchOnPayloadChange,
  disabled,
}: DataSourceWorkflowNodeProps) {
  const computedPayload = useComputeDataSourcePayload<{
    inputs: AutomationInputsType;
    context?: NodeContextType;
  }>({
    data: { inputs, context },
    refetchOnPayloadChange,
  });

  const decoratedInputs = useAddSystemParameters(computedPayload);
  const queryOptions = useGenerateQueryOptionsFromAdvancedOptions(advancedOptions);

  const queryResult = useDataSourceWorkflowNode({
    payload: {
      context: computedPayload.context,
      id,
      inputs: decoratedInputs,
      lookupRequests,
    },
    enabled: !disabled,
    debugParams: {
      name,
      requestId,
    },
    additionalParams: callbacks,
    queryOptions,
  });

  useEffect(() => {
    const currentQueryResult = getCurrentQueryResult({
      id,
      requestId,
    });

    if (isEqual(_pick(queryResult, KEYS_TO_MATCH), _pick(currentQueryResult, KEYS_TO_MATCH))) {
      return;
    }

    setDataSourceState({
      id,
      result: queryResult,
      requestId,
    });
  }, [getCurrentQueryResult, queryResult, id, setDataSourceState, requestId]);

  useExecuteDataSourceCallbacks({
    callbacks,
    onQueryCompletion,
    requestId,
    id,
    queryResult: {
      error: queryResult.error,
      isLoading: queryResult.isLoading,
      isFetching: queryResult.isFetching,
    },
  });

  return null;
}

export default DataSourceWorkflowNode;
