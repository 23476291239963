import type { InterfaceEntity } from '@unifyapps/defs/types/interface';
import {
  useFindEntityById,
  useSearchEntities,
} from '@unifyapps/network/generated/entity-rest-api/entity-rest-api';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import { type InterfacePageEntity } from '@unifyapps/defs/types/page';
import { InterfaceClient } from '../../../../stores/InterfaceStore';
import { useGetAuthInterface } from '../../../../../hooks/useAuthInterface';
import { useFetchDataSourcesByComponentId } from '../useFetchDataSourcesByComponentId';
import { useInterfaceModules } from '../useFetchInterfaceModules';
import { getLookupInterfaceQueryFilter } from '../../../../utils/interfaceQueryFilters';
import getHost from './host';
import type { UseFetchResourcesHookType } from './type';

const useFetchInterfaceResources: UseFetchResourcesHookType = ({
  interfaceId,
  interfaceComponent,
  client,
}) => {
  const authEntityInterface = useGetAuthInterface<InterfaceEntity>({
    // UA_MATRIX is for matrix app, so there we fetch from auth/interface endpoint that uses domain
    query: {
      enabled: Boolean(getHost()) && client === InterfaceClient.UA_MATRIX,
      retry: 3,
    },
  });

  const { data, isLoading } = useFindEntityById<InterfaceEntity>(
    UAEntityTypes.Interface,
    interfaceId,
    {
      query: {
        enabled: Boolean(interfaceId) && client === InterfaceClient.UA_PLATFORM,
        retry: 3,
      },
    },
  );

  const interfaceRecord = authEntityInterface.data || data;
  const isLoadingInterfaceRecord = authEntityInterface.isLoading || isLoading;
  const errorInterfaceRecord = authEntityInterface.error;

  const {
    data: pageEntities,
    isLoading: isLoadingPage,
    error: errorPage,
  } = useSearchEntities(
    UAEntityTypes.Component,
    getLookupInterfaceQueryFilter({ interfaceId, interfaceComponent }),
    {
      query: {
        enabled: Boolean(interfaceId),
        retry: 3,
      },
    },
  );

  const interfacePage = pageEntities?.objects?.[0] as InterfacePageEntity | undefined;

  const { dataSources, errorDS, isLoadingDS } = useFetchDataSourcesByComponentId(
    // If we're getting id from interfaceComponent, use that, otherwise use id from interfacePage
    (interfaceComponent.id || interfacePage?.id) ?? '',
  );

  const {
    interfaceModulesMap,
    isLoading: isLoadingInterfaceModules,
    error: interfaceModulesError,
  } = useInterfaceModules({
    interfaceId,
  });

  const _isLoading =
    isLoadingInterfaceRecord || isLoadingPage || isLoadingDS || isLoadingInterfaceModules;

  // Uncomment this line to debug ssr -- they all should be prefetched i.e. all should be false
  // console.log(
  //   `isLoading:${_isLoading} isLoadingInterfaceRecord:${isLoadingInterfaceRecord} ; isLoadingPage:${isLoadingPage}; isLoadingDS:${isLoadingDS}; isLoadingInterfaceModules:${isLoadingInterfaceModules} `,
  // );

  return {
    interfaceModulesMap,
    isLoading: _isLoading,
    error: errorInterfaceRecord || errorPage || errorDS || interfaceModulesError,
    data: interfaceRecord,
    dataSources,
    interfacePage,
  };
};

export default useFetchInterfaceResources;
