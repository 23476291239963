import _find from 'lodash/find';
import { invariant } from 'ts-invariant';
import type {
  PageVariableState,
  SetVariablePayloadType,
  SetVariableArrayTypePayload,
  SetVariableBooleanTypePayload,
  SetVariableNumberTypePayload,
  SetVariableObjectTypePayload,
  SetVariableStringTypePayload,
} from '@unifyapps/defs/types/pageVariable';
import { PageVariableType } from '@unifyapps/defs/types/pageVariable';
import type {
  ActionHandlerType,
  OnActionArgs,
} from '../../../../components/ActionsProvider/context';
import {
  useGetGlobalStateStoreState,
  useGlobalStateStore,
} from '../../../../stores/GlobalStateStore';
import { useInterfaceStore } from '../../../../stores/InterfaceStore';
import { getComputedData } from '../../../../utils/getComputedData';
import {
  getNextPageVariableStateForArray,
  getNextPageVariableStateForBoolean,
  getNextPageVariableStateForNumber,
  getNextPageVariableStateForObject,
  getNextPageVariableStateForString,
} from '../../utils/getNextState';

function useSetPageVariable() {
  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const { setPageVariableState } = useGlobalStateStore().use.actions();
  const interfacePageVariables = useInterfaceStore().use.page.pageVariables();

  const onSetPageVariable: ActionHandlerType = ({ action, actionContext }: OnActionArgs) => {
    const payload = action.payload as unknown as SetVariablePayloadType;

    const pageVariableDetails = interfacePageVariables?.[payload.variableId];

    const pageVariablesState = getGlobalStateStoreState().pageVariables;
    const variableState = _find(pageVariablesState, {
      id: payload.variableId,
    });

    invariant(variableState, `VariableState with id ${payload.variableId} not found`);
    invariant(
      pageVariableDetails,
      `VariableDetails in interfacePage with id ${payload.variableId} not found`,
    );

    const { value: operationValue } = getComputedData<{
      value: unknown;
    }>(
      {
        value: payload.operationDetails.value,
      },
      actionContext,
    );

    const { value: currentVariableState } = getComputedData<{
      value: unknown;
    }>(
      {
        value: variableState.value,
      },
      actionContext,
    );

    let nextPageVariableState;

    switch (pageVariableDetails.type) {
      case PageVariableType.String:
        nextPageVariableState = getNextPageVariableStateForString({
          variableState: {
            ...variableState,
            value: currentVariableState,
          },
          operationDetails: {
            ...(payload.operationDetails as SetVariableStringTypePayload['operationDetails']),
            value: operationValue,
          },
        });
        break;
      case PageVariableType.Number:
        nextPageVariableState = getNextPageVariableStateForNumber({
          variableState: {
            ...variableState,
            value: currentVariableState,
          },
          operationDetails: {
            ...(payload.operationDetails as SetVariableNumberTypePayload['operationDetails']),
            value: operationValue,
          },
        });
        break;
      case PageVariableType.Boolean:
        nextPageVariableState = getNextPageVariableStateForBoolean({
          variableState: {
            ...variableState,
            value: currentVariableState,
          },
          operationDetails: {
            ...(payload.operationDetails as SetVariableBooleanTypePayload['operationDetails']),
            value: operationValue,
          },
        });
        break;
      case PageVariableType.Object:
        nextPageVariableState = getNextPageVariableStateForObject({
          variableState: {
            ...variableState,
            value: currentVariableState,
          },
          operationDetails: {
            ...(payload.operationDetails as SetVariableObjectTypePayload['operationDetails']),
            value: operationValue,
          },
        });
        break;
      case PageVariableType.Array:
        nextPageVariableState = getNextPageVariableStateForArray({
          variableState: {
            ...variableState,
            value: currentVariableState,
          },
          operationDetails: {
            ...(payload.operationDetails as SetVariableArrayTypePayload['operationDetails']),
            value: operationValue,
          },
        });
        break;
    }

    setPageVariableState(payload.variableId, nextPageVariableState as PageVariableState);
    return Promise.resolve();
  };

  return { onSetPageVariable };
}

export default useSetPageVariable;
