import { useEffect, useMemo } from 'react';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import type { DataSource } from '@unifyapps/defs/types/dataSource';
import { keepPreviousData } from '@unifyapps/network/react-query';
import type { AggregationMetadataLookupDTOInputs } from '@unifyapps/network/generated/models/aggregationMetadataLookupDTOInputs';
import { useLookupNodeAggregationMetadata } from '@unifyapps/network/generated/workflow-builder-rest-api/workflow-builder-rest-api';
import isEqual from 'react-fast-compare';
import { useComputeDataSourcePayload } from '../../../hooks/useComputeDataSourcePayload';
import type { QueryRequestResult } from '../../../../../stores/GlobalStateStore';
import {
  useGetGlobalStateStoreState,
  useGlobalStateStore,
} from '../../../../../stores/GlobalStateStore';

interface AggregationFieldQueryNodeProps {
  dataSourceId: string;
  dataSource?: DataSource;
}

const INPUTS_KEYS_TO_OMIT = ['page', 'searchField', 'sortBy', 'sorts', 'triggerInputCondition'];

const useAggregationFieldQueryNode = (props: AggregationFieldQueryNodeProps) => {
  const { dataSourceId, dataSource } = props;

  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const { setAggregationMetadataInDSMeta } = useGlobalStateStore().use.actions();
  const evaluatedInputs = useComputeDataSourcePayload<{
    value: AggregationMetadataLookupDTOInputs | undefined;
  }>({
    data: { value: dataSource?.inputs },
  });

  const response = useLookupNodeAggregationMetadata(
    {
      context: dataSource?.context,
      // omitting these fields such that the metadata query is not triggered on filter/sort/page/search change
      inputs: omit(evaluatedInputs.value, INPUTS_KEYS_TO_OMIT),
      request: { filterable: true, sortable: true, updatable: true },
    },
    {
      query: {
        enabled: !isEmpty(evaluatedInputs.value),
        retry: false,
        placeholderData: keepPreviousData, // added to avoid empty data on refetch
      },
    },
  );

  const queryResult: QueryRequestResult = useMemo(() => {
    const { isLoading, isFetching, error, data, queryKey } = response;
    return {
      isLoading,
      isFetching,
      error,
      data,
      queryKey,
    };
  }, [response]);

  useEffect(() => {
    const prevDataSourceMetaData = getGlobalStateStoreState().dataSourcesMeta[dataSourceId];
    if (isEqual(prevDataSourceMetaData?.aggregationMetadata, queryResult)) return;
    setAggregationMetadataInDSMeta(dataSourceId, queryResult);
  }, [dataSourceId, getGlobalStateStoreState, queryResult, setAggregationMetadataInDSMeta]);
};

export default useAggregationFieldQueryNode;
