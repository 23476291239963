import { isCellEditable } from 'material-react-table/src/utils/cell.utils';
import type { MRT_Cell, MRT_RowData, MRT_TableInstance } from '../types';

// same code as openEditingCell from 'material-react-table/src/utils/cell.utils';
// duplicated since we are using custom store for editingCell state
export const openEditingCell = <TData extends MRT_RowData>({
  cell,
  table,
}: {
  cell: MRT_Cell<TData>;
  table: MRT_TableInstance<TData>;
}) => {
  const {
    options: { editDisplayMode },
    refs: { editInputRefs },
  } = table;
  const { column } = cell;

  if (isCellEditable({ cell, table }) && editDisplayMode === 'cell') {
    const setEditingCellId = table.options.tableStateStore?.getState().actions.setEditableCellId;
    setEditingCellId?.(cell.id);
    queueMicrotask(() => {
      const textField = editInputRefs.current[column.id] as HTMLInputElement | null;
      if (textField) {
        textField.focus();
        textField.select();
      }
    });
  }
};

export { isCellEditable };
