import isEqual from 'react-fast-compare';
import { useEffect } from 'react';
import {
  useGetGlobalStateStoreState,
  useGlobalStateStore,
} from '../../../../stores/GlobalStateStore';
import { useGetUserContext } from '../../../../hooks/useGetUserContext';

function useSetUserInStore() {
  const { setUserContext } = useGlobalStateStore().use.actions();
  const { userContext } = useGetUserContext();
  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();

  useEffect(() => {
    const userContextInStore = getGlobalStateStoreState().userContext;
    if (userContext && !isEqual(userContext, userContextInStore)) {
      setUserContext(userContext);
    }
  }, [setUserContext, userContext, getGlobalStateStoreState]);
}

export default useSetUserInStore;
