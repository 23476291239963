import type {
  ComputedPageVariableState,
  SetVariableNumberTypePayload,
} from '@unifyapps/defs/types/pageVariable';
import _isUndefined from 'lodash/isUndefined';

export function getNextPageVariableStateForNumber({
  variableState,
  operationDetails,
}: {
  variableState: ComputedPageVariableState<unknown>;
  operationDetails: {
    operation: SetVariableNumberTypePayload['operationDetails']['operation'];
    value?: unknown;
  };
}) {
  switch (operationDetails.operation) {
    case 'SET': {
      if (!_isUndefined(operationDetails.value) && Number.isNaN(Number(operationDetails.value))) {
        console.error('operationDetails.value should be number for set operation');
        return;
      }
      return {
        ...variableState,
        value: Number(operationDetails.value),
      };
    }
    case 'INCREMENT_BY': {
      if (
        (!_isUndefined(variableState.value) && Number.isNaN(Number(variableState.value))) ||
        (!_isUndefined(operationDetails.value) && Number.isNaN(Number(operationDetails.value)))
      ) {
        console.error(
          'variableState.value and operationDetails.value should be number for increment operation',
        );
        return;
      }
      return {
        ...variableState,
        value: Number(variableState.value) + Number(operationDetails.value),
      };
    }
    case 'DECREMENT_BY': {
      if (
        (!_isUndefined(variableState.value) && Number.isNaN(Number(variableState.value))) ||
        (!_isUndefined(operationDetails.value) && Number.isNaN(Number(operationDetails.value)))
      ) {
        console.error(
          'variableState.value and operationDetails.value should be number for decrement operation',
        );
        return;
      }
      return {
        ...variableState,
        value: Number(variableState.value) - Number(operationDetails.value),
      };
    }
  }
}
