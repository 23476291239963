import { useEffect, useTransition } from 'react';
import _isString from 'lodash/isString';
import _isEmpty from 'lodash/isEmpty';
import { useGlobalStateStore } from '../../../../stores/GlobalStateStore/GlobalStateStoreProvider';
import { useGetComputeContext } from '../../../../hooks/computeContext';
import { getComputedData } from '../../../../utils/getComputedData';
import { isDynamicValue } from '../../../../utils/dynamicBindings';

const useSetPageVariablesValues = () => {
  const pageVariables = useGlobalStateStore().use.pageVariables();
  const { setPageVariablesState } = useGlobalStateStore().use.actions();
  const [_, startTransition] = useTransition();

  const { getComputeContext } = useGetComputeContext();

  useEffect(() => {
    const computedPageVariableValueMap = Object.entries(pageVariables).reduce(
      (acc, [variableId, pageVariable]) => {
        if (pageVariable && _isString(pageVariable.value) && isDynamicValue(pageVariable.value)) {
          const { value: computedValue } = getComputedData<{ value: unknown }>(
            {
              value: pageVariable.value,
            },
            getComputeContext(),
          );

          acc[variableId] = {
            ...pageVariable,
            value: computedValue,
          };
        }
        return acc;
      },
      {},
    );

    if (!_isEmpty(computedPageVariableValueMap)) {
      startTransition(() => {
        setPageVariablesState(computedPageVariableValueMap);
      });
    }
  }, [getComputeContext, pageVariables, setPageVariablesState]);
};

export default useSetPageVariablesValues;
