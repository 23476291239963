import type {
  ComputedPageVariableState,
  SetVariableObjectTypePayload,
} from '@unifyapps/defs/types/pageVariable';
import _isUndefined from 'lodash/isUndefined';
import { EMPTY_OBJECT } from '../../../../../consts/empty';

export function getNextPageVariableStateForObject({
  variableState,
  operationDetails,
}: {
  variableState: ComputedPageVariableState<unknown>;
  operationDetails: {
    operation: SetVariableObjectTypePayload['operationDetails']['operation'];
    value?: unknown;
  };
}) {
  switch (operationDetails.operation) {
    case 'SET': {
      if (!_isUndefined(operationDetails.value) && typeof operationDetails.value !== 'object') {
        console.error('operationDetails.value should be object for set operation');
        return;
      }
      return {
        ...variableState,
        value: operationDetails.value ?? EMPTY_OBJECT,
      };
    }
    case 'MERGE': {
      if (
        (!_isUndefined(variableState.value) && typeof variableState.value !== 'object') ||
        (!_isUndefined(operationDetails.value) && typeof operationDetails.value !== 'object')
      ) {
        console.error(
          'variableState.value and operationDetails.value should be object for merge operation',
        );
      }

      return {
        ...variableState,
        value: {
          ...(variableState.value as object),
          ...(operationDetails.value as object),
        },
      };
    }
  }
}
