import { useMutation } from '@unifyapps/network/react-query';
import type {
  UseMutationResult,
  MutationFunction,
  UseMutationOptions,
} from '@unifyapps/network/react-query';
import type { ErrorType } from '@unifyapps/network/utils/fetch';
import { executeFetch } from '@unifyapps/network/utils/fetch';

export type AuthLoginRequest = {
  formData: unknown;
  identityProviderId: string;
  returnTo?: string;
};

export const authLogin = (authLoginRequest: AuthLoginRequest) => {
  return executeFetch<{
    redirectUrl: string;
    sessionId?: string;
  }>({
    url: `/auth/login`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: authLoginRequest,
  });
};

export const getAuthLoginMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authLogin>>,
    TError,
    { data: AuthLoginRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof authLogin>>,
  TError,
  { data: AuthLoginRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authLogin>>,
    { data: AuthLoginRequest }
  > = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- nullish check
    const { data } = props ?? {};

    return authLogin(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthLoginMutationResult = NonNullable<Awaited<ReturnType<typeof authLogin>>>;
export type AuthLoginMutationBody = AuthLoginRequest;
export type AuthLoginMutationError = unknown;

export const useAuthLogin: <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authLogin>>,
    TError,
    { data: AuthLoginRequest },
    TContext
  >;
}) => UseMutationResult<
  Awaited<ReturnType<typeof authLogin>>,
  TError,
  { data: AuthLoginRequest },
  TContext
> = (options) => {
  const mutationOptions = getAuthLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
