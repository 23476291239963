import { produce } from 'immer';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import type { ExecuteWorkflowNodeRequest } from '@unifyapps/network/generated/models/executeWorkflowNodeRequest';
import type { ExecuteWorkflowNodeResponse } from '@unifyapps/network/generated/models/executeWorkflowNodeResponse';
import { mutateDataWithLookupReferences } from './mutateDataWithLookupReferences';

const wildcard = `[*].`;

export const adaptResponseWithLookupReferences = (
  data?: ExecuteWorkflowNodeResponse,
  lookupRequests?: ExecuteWorkflowNodeRequest['lookupRequests'],
) => {
  if (!data?.response || !lookupRequests?.length || isEmpty(data.lookupReferences)) {
    return data?.response;
  }

  const lookupReferences = data.lookupReferences;

  return produce(data.response, (draft) => {
    lookupRequests.forEach((lookupRequest) => {
      const name = lookupRequest.name;
      // @ts-expect-error -- should be present when lookupRequest is byKeys
      let keys = lookupRequest.keys as string | string[];

      if (Array.isArray(keys)) {
        // backward compatibility for old lookupRequests - earlier it was a string
        keys = keys[0];
      }

      if (!name || typeof keys !== 'string') {
        return;
      }

      const hasWildcard = keys.includes(wildcard);

      if (hasWildcard) {
        const [list, path] = keys.split(wildcard);

        if (!list || !path) {
          return;
        }

        const listData = get(draft, list) as unknown[];

        if (!Array.isArray(listData)) {
          return;
        }

        listData.forEach((item) => {
          mutateDataWithLookupReferences({
            data: item as Record<string, unknown>,
            path,
            name,
            lookupReferences,
          });
        });
      } else {
        mutateDataWithLookupReferences({
          data: draft,
          path: keys,
          name,
          lookupReferences,
        });
      }
    });
  });
};
