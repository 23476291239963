import { REDIRECT_TO_KEY, SAML_LOGIN_URL } from '@unifyapps/network/auth/const';
import { useCallback } from 'react';
import type { IChangeEvent } from '@unifyapps/form/types';
import { useSnackbar } from '@unifyapps/ui/components/Snackbar';
import { useTranslation } from '@unifyapps/i18n/client';
import type {
  ActionHandlerType,
  OnActionArgs,
} from '../../../../components/ActionsProvider/context';
import { useAuthLogin } from '../../../../../hooks/useAuthLogin';
import { useRouter } from '../../../useRouter';

type LoginActionType = {
  method?: 'sso' | 'password';
  identityProviderId?: string;
};

const useLogin = () => {
  const { searchParams: params } = useRouter();
  const { mutate: executeAuthLogin } = useAuthLogin();
  const { showSnackbar } = useSnackbar();
  const redirectPath = params.get(REDIRECT_TO_KEY) ?? '/';
  const { t } = useTranslation(['common']);

  const onLogin = useCallback(
    (loginPath: string) => {
      const redirectUrlFromPath = new URL(redirectPath, window.location.origin).toString();

      const loginUrl = new URL(loginPath, window.location.origin);
      loginUrl.searchParams.set(REDIRECT_TO_KEY, redirectUrlFromPath);
      window.location.href = loginUrl.toString();
    },
    [redirectPath],
  );

  const onFormLogin = useCallback(
    (identityProviderId: string, data: IChangeEvent) => {
      const formData: unknown = data.formData;

      executeAuthLogin(
        {
          data: {
            formData,
            identityProviderId,
            returnTo: redirectPath,
          },
        },
        {
          onSuccess: ({ redirectUrl }: { redirectUrl: string }) => {
            window.location.href = redirectUrl;
          },
          onError: (error) => {
            showSnackbar({
              color: 'error',
              title: error.payload?.message ?? t('common:ErrorState.SomethingWentWrong'),
            });
          },
        },
      );
    },
    [executeAuthLogin, redirectPath, showSnackbar, t],
  );

  const login: ActionHandlerType = ({ action, domEvent }: OnActionArgs) => {
    const loginAction = action.payload as LoginActionType;
    const { identityProviderId, method } = loginAction;
    if (!identityProviderId) return Promise.resolve();

    if (method === 'sso') {
      onLogin(`${SAML_LOGIN_URL}?identityProviderId=${identityProviderId}`);
    } else if (method === 'password') {
      onFormLogin(identityProviderId, domEvent as unknown as IChangeEvent);
    }
    return Promise.resolve();
  };

  return {
    login,
  };
};

export default useLogin;
