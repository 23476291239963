import _set from 'lodash/set';
import _get from 'lodash/get';
import { produce } from 'immer';
import { useCallback } from 'react';
import { useEntityLocalStorageValueAndSetter } from '../../../../hooks/useLocalStorage';
import { LocalStorageKeys } from '../../../../consts/localStorageKeys';

export function useInterfaceLocalStorage<T>({
  key,
  options,
  enabled,
}: {
  key: string;
  options?: { onSuccess?: (data: T | undefined) => void };
  enabled?: boolean;
}) {
  const { onSuccess } = options ?? {};

  const onSuccessWrapper = useCallback(
    (data: Record<string, unknown> | undefined) => {
      const value = _get(data, key) as T;
      onSuccess?.(value);
    },
    [key, onSuccess],
  );

  const { data, loading, setEntityValue } = useEntityLocalStorageValueAndSetter<
    Record<string, unknown>
  >(LocalStorageKeys.Interfaces, { onSuccess: onSuccessWrapper }, enabled);

  const setValue = useCallback(
    (value: T) => {
      const updatedData = produce(data ?? {}, (draft) => {
        _set(draft, key, value);
      });
      setEntityValue(updatedData);
    },
    [data, key, setEntityValue],
  );

  const value = _get(data, key) as T;

  return {
    value,
    loading,
    setValue,
  };
}
