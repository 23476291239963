import { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import type { LookupNodesOutputSchemaBody } from '@unifyapps/network/generated/models/lookupNodesOutputSchemaBody';
import { getLookupNodesOutputSchemaQueryOptions } from '@unifyapps/network/generated/workflow-builder-rest-api/workflow-builder-rest-api';
import { useQueryClient } from '@unifyapps/network/react-query';
import {
  useDataSourceRecordStore,
  useDataSourceRecords,
} from '../../../../stores/DataSourceRecordStore';
import { InterfaceModes, type InterfaceStoreState } from '../../../../stores/InterfaceStore';
import getPayloadForDataSourceOutputSchema from '../../../../utils/getPayloadForDataSourceOutputSchema';

function useCollectDataSourcesOutputSchemas(mode: InterfaceStoreState['mode']) {
  const dataSources = useDataSourceRecords();
  const { setDataSourceSchemas } = useDataSourceRecordStore().use.actions();
  const localDataSourceCopies = useRef<typeof dataSources | undefined>();
  const [isFetching, setIsFetching] = useState(false);
  const queryClient = useQueryClient();

  const fetchDataSourceOutputSchema = useCallback(
    async (dataSourceIds: string[]) => {
      setIsFetching(true);

      const payload = dataSourceIds.reduce<LookupNodesOutputSchemaBody>((acc, dataSourceId) => {
        const dataSource = dataSources[dataSourceId];
        if (!dataSource) return acc;

        acc[dataSourceId] = getPayloadForDataSourceOutputSchema(dataSource);
        return acc;
      }, {});

      const data = await queryClient.fetchQuery({
        ...getLookupNodesOutputSchemaQueryOptions(payload),
        retry: false,
      });

      setDataSourceSchemas(data);
      setIsFetching(false);
      localDataSourceCopies.current = dataSources;
    },
    [dataSources, queryClient, setDataSourceSchemas],
  );

  useEffect(() => {
    if (mode !== InterfaceModes.BUILDER) return;
    const changedDataSourcesIds = Object.keys(dataSources).filter(
      (key) => !isEqual(dataSources[key], localDataSourceCopies.current?.[key]),
    );

    if (changedDataSourcesIds.length === 0 || isFetching) return;
    void fetchDataSourceOutputSchema(changedDataSourcesIds);
  }, [dataSources, fetchDataSourceOutputSchema, isFetching, mode]);
}

export default useCollectDataSourcesOutputSchemas;
