export const INFINITE_KEYS_TO_MATCH = [
  'isLoading',
  'isFetching',
  'error',
  'data',
  'queryKey',
  'isFetchingNextPage',
  'hasNextPage',
];

export const KEYS_TO_MATCH = ['isLoading', 'isFetching', 'error', 'data', 'queryKey'];
