import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type { OnActionArgs, OnActionType } from './context';
import { ActionsProviderContext, useActionsContext } from './context';

export default function ActionsProvider({
  children,
  onAction,
  isGlobalActionsProvider,
}: {
  children: ReactNode;
  onAction: OnActionType;
  isGlobalActionsProvider?: boolean;
}) {
  const { doAction: parentDoAction } = useActionsContext();
  const contextValue = useMemo(() => {
    return {
      doAction: async ({ action, domEvent, actionContext, blockId }: OnActionArgs) => {
        const shouldPropagate = await onAction({ action, domEvent, actionContext, blockId });

        // When shouldPropagate is false, we don't want to propagate the action to the parent
        if (shouldPropagate !== false && !isGlobalActionsProvider) {
          await parentDoAction?.({
            action,
            domEvent,
            actionContext,
            blockId,
          });
        }
      },
    };
  }, [isGlobalActionsProvider, onAction, parentDoAction]);

  return (
    <ActionsProviderContext.Provider value={contextValue}>
      {children}
    </ActionsProviderContext.Provider>
  );
}
