import type { JSONSchema7 } from 'json-schema';

export function getDefaultValuesFromSchema(schema: JSONSchema7) {
  const defaultValues: Record<string, unknown> = {};

  Object.entries(schema.properties ?? {}).forEach(([key, value]) => {
    const defaultValue = (value as JSONSchema7).default;
    if (defaultValue !== undefined) {
      defaultValues[key] = defaultValue;
    }
  });

  return defaultValues;
}
