import { useRef, useImperativeHandle } from 'react';
import type { InterfaceResourceProviderRefType } from '../types';
import type { GlobalStateStoreRefType } from '../../../stores/GlobalStateStore/type';
import type { InterfaceStoreRefType } from '../../../stores/InterfaceStore';

export const useStoreRefs = ({
  interfaceResourceProviderRef,
}: {
  interfaceResourceProviderRef?: React.MutableRefObject<
    InterfaceResourceProviderRefType | undefined
  >;
}) => {
  const globalStateStoreRef = useRef<GlobalStateStoreRefType | undefined>();
  const interfaceStoreRef = useRef<InterfaceStoreRefType | undefined>();

  useImperativeHandle(interfaceResourceProviderRef, () => {
    return {
      updateBlocks: (params) => {
        // Call updateBlocks on both stores
        globalStateStoreRef.current?.updateBlocks(params);
        interfaceStoreRef.current?.updateBlocks(params);
      },
    };
  }, []);

  return {
    globalStateStoreRef,
    interfaceStoreRef,
  };
};
