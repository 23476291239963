import { useCallback } from 'react';
import type { GlobalStateStoreFlows } from '../../../../stores/GlobalStateStore';
import { useGlobalStateStore } from '../../../../stores/GlobalStateStore';
import type {
  ActionHandlerType,
  OnActionArgs,
} from '../../../../components/ActionsProvider/context';
import { getComputedData } from '../../../../utils/getComputedData';
import { useGetComputeContext } from '../../../computeContext';

function useImportObjects() {
  const { setPreBuiltFlow } = useGlobalStateStore().use.actions();

  const { getComputeContext } = useGetComputeContext();

  const importObjects: ActionHandlerType = useCallback(
    ({ action }: OnActionArgs) => {
      const payload = action.payload as GlobalStateStoreFlows['importObjectRecords'];
      const computedPayload = getComputedData<GlobalStateStoreFlows['importObjectRecords']>(
        payload ?? {},
        getComputeContext(),
      );

      if (payload?.objectId) {
        return new Promise((resolve, reject) => {
          setPreBuiltFlow({
            type: 'importObjectRecords',
            data: {
              ...computedPayload,
              onSuccess: () => resolve(),
              onClose: () => resolve(),
              onError: () => reject(),
            },
          });
        });
      }
      return Promise.resolve();
    },
    [setPreBuiltFlow, getComputeContext],
  );

  return { importObjects };
}

export default useImportObjects;
