import { getIsDataSourceAnEntityType } from '../../../../../utils/entityDataSource';
import { useDataSourceRecords } from '../../../../../stores/DataSourceRecordStore';
import { useGetEntityTypeDataSources } from '../../../hooks/useGetEntityTypeDataSources';
import useAggregationFieldQueryNode from './useAggregationFieldQueryNode';

const useGetDataSourceRecord = (dataSourceId: string) => {
  const dataSources = useDataSourceRecords();
  const { entityTypeDataSources } = useGetEntityTypeDataSources();
  if (getIsDataSourceAnEntityType(dataSourceId)) {
    return entityTypeDataSources[dataSourceId];
  }
  return dataSources[dataSourceId]?.properties;
};

function AggregationFieldQueryNode({ dataSourceId }: { dataSourceId: string }) {
  const dataSourceRecord = useGetDataSourceRecord(dataSourceId);
  useAggregationFieldQueryNode({ dataSourceId, dataSource: dataSourceRecord });

  return null;
}

export default AggregationFieldQueryNode;
