import type { BlockType } from '@unifyapps/defs/types/block';
import { useEffect, useMemo } from 'react';
import DependencyFlowHelper from '../../../../stores/GlobalStateStore/helpers/DependencyFlowHelper';
import { useGlobalStateStore } from '../../../../stores/GlobalStateStore';
import { useInterfaceStore } from '../../../../stores/InterfaceStore';
import { getIsDataSourceAnEntityType } from '../../../../utils/entityDataSource';
import {
  useDataSourceRecords,
  type DataSourceRecordStoreState,
} from '../../../../stores/DataSourceRecordStore';
import AggregationMetadataQueryNode from './AggregationMetadataQueryNode/AggregationMetadataQueryNode';
import {
  AGGREGATE_METADATA_DATA_SOURCE_RESOURCE_NAME,
  getDataSourcesForAggregationMetadata,
} from './utils';

function createAggregationMetadataDependencyGraph(
  blocks: Record<string, BlockType> | undefined,
  dataSources: DataSourceRecordStoreState['dataSources'],
) {
  const dataSourcesForAggregationMetadata = new Set<string>();
  const nodeExecuteDataSources = new Set<string>();

  if (!blocks)
    return {
      dataSourcesForAggregationMetadata,
      nodeExecuteDataSources,
    };

  Object.values(blocks).forEach((block) => {
    const dataSourceIds = getDataSourcesForAggregationMetadata(block, dataSources) ?? [];

    if (!dataSourceIds.length) return;

    dataSourceIds.forEach((dataSourceId) => {
      // for entity type datasourceId we fetch the metadata upfront because we need them for tags cell type in table
      if (getIsDataSourceAnEntityType(dataSourceId)) {
        dataSourcesForAggregationMetadata.add(dataSourceId);
      } else {
        const dataSource = dataSources[dataSourceId];
        const resourceName = dataSource?.properties.context?.resourceName ?? '';
        // here we are checking that the dataSourceId we want metadata from needs to be executed through normal execute/node endpoint
        if (AGGREGATE_METADATA_DATA_SOURCE_RESOURCE_NAME.has(resourceName)) {
          nodeExecuteDataSources.add(dataSourceId);
        } else {
          dataSourcesForAggregationMetadata.add(dataSourceId);
        }
      }
    });
  });

  return {
    dataSourcesForAggregationMetadata,
    nodeExecuteDataSources,
  };
}

function AggregationMetadataHandler() {
  const blocks = useInterfaceStore().use.blocks.fromBaseDevice();
  const dataSources = useDataSourceRecords();
  const dsDependencyFlow = useGlobalStateStore().use.dependencyFlow.dataSources();
  const { datasource } = useGlobalStateStore().use.actions();
  const { dataSourcesForAggregationMetadata, nodeExecuteDataSources } = useMemo(
    () => createAggregationMetadataDependencyGraph(blocks, dataSources),
    [blocks, dataSources],
  );

  const dataSourcesToRequest = useMemo(() => {
    const nodeExecuteDataSourcesArray = Array.from(nodeExecuteDataSources);
    return nodeExecuteDataSourcesArray.filter((dataSourceId) =>
      DependencyFlowHelper.isDependencyUnstaged(dsDependencyFlow, dataSourceId),
    );
  }, [dsDependencyFlow, nodeExecuteDataSources]);

  useEffect(() => {
    // for the data sources that needs to be executed to get the aggregation metadata, we request them so that they are executed normally using the automaticedatasourcehandler
    if (dataSourcesToRequest.length) {
      const adaptedPayload = DependencyFlowHelper.createStagingDependencyFlow({
        ids: dataSourcesToRequest,
      });
      datasource.addDependencyFlows(adaptedPayload);
    }
  }, [dataSourcesToRequest, datasource]);

  return (
    <>
      {Array.from(dataSourcesForAggregationMetadata).map((dataSourceId) => {
        return <AggregationMetadataQueryNode dataSourceId={dataSourceId} key={dataSourceId} />;
      })}
    </>
  );
}

export default AggregationMetadataHandler;
