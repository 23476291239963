import { useCallback } from 'react';
import { invariant } from 'ts-invariant';
import type { OnActionType } from '../../../../components/ActionsProvider/context';
import useControlBlockMethod from '../controlBlockMethod/useControlBlockMethod';

type DrawerActionPayload = {
  drawerId?: string;
  operation: 'show' | 'hide';
};

function useControlDrawerActions() {
  const { onCallBlockMethod } = useControlBlockMethod();

  const onControlDrawer: OnActionType = useCallback(
    async ({ action, actionContext }) => {
      const payload = action.payload as DrawerActionPayload;
      const blockId = payload.drawerId;
      invariant(blockId, 'Drawer id is required');

      switch (payload.operation) {
        case 'show':
          await onCallBlockMethod({
            blockId,
            methodName: 'showDrawer',
            action,
            actionContext,
          });
          break;
        case 'hide':
          await onCallBlockMethod({
            blockId,
            methodName: 'hideDrawer',
            action,
            actionContext,
          });
          break;
        default:
          break;
      }
      return Promise.resolve();
    },
    [onCallBlockMethod],
  );

  return { onControlDrawer };
}

export default useControlDrawerActions;
