import { UAResources } from '@unifyapps/defs/types/resources';
import type { DataSource } from '@unifyapps/defs/types/dataSource';
import type { BlockType } from '@unifyapps/defs/types/block';
import { getSelectedDataSourcePath } from '../../utils/getSelectedDataSourcePath';
import type { DataSourceRecordStoreState } from '../stores/DataSourceRecordStore';
import { getIsDataSourceAnEntityType } from './entityDataSource';

interface Params {
  dataSourceId?: string;
  dataSource?: DataSource;
}

/**
 * Set of resource names that have aggregation metadata when requested on metadata endpoint
 */
export const resourceNamesWithMetadataSet = new Set<string>([
  UAResources.StorageByUAFetchRecords,
  UAResources.AnalyticsByUAQueryReports,
  UAResources.StandardEntitiesFetchEntities,
]);

export const getDataSourceHasAggregationMetadata = (params: Params) => {
  const { dataSource, dataSourceId } = params;

  // all entity type data sources have aggregation metadata, because they are from storage node
  if (dataSourceId && getIsDataSourceAnEntityType(dataSourceId)) {
    return true;
  }

  return resourceNamesWithMetadataSet.has(dataSource?.context?.resourceName ?? '');
};

export const getBlockContentDataSource = (
  block: BlockType,
  dataSources: DataSourceRecordStoreState['dataSources'],
) => {
  switch (block.component.componentType) {
    case 'Table':
    case 'NestedTable': {
      const { dataSourceId } = getSelectedDataSourcePath({ data: block.component.content.data });
      const dataSource = dataSourceId ? dataSources[dataSourceId]?.properties : undefined;

      return { dataSource, dataSourceId };
    }
    default: {
      return {};
    }
  }
};
