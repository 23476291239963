import isEqual from 'react-fast-compare';
import { useEffect } from 'react';
import type { DataSource, QueryInputsType } from '@unifyapps/defs/types/dataSource';
import { useComputeDataSourcePayload } from '../../hooks/useComputeDataSourcePayload';
import { useGlobalStateStore } from '../../../../stores/GlobalStateStore';
import { useExecuteDataSourceCallbacks } from '../../hooks/useExecuteDataSourceCallbacks';
import type { QueryNode } from '../../types';
import useUAQuerySource from './useUAQuerySource';

function UAQueryNode({
  id,
  query,
  onQueryCompletion,
  callbacks,
  requestId,
}: {
  id: string;
  query: QueryInputsType;
  callbacks: DataSource['callbacks'];
  onQueryCompletion: QueryNode['onQueryCompletion'];
  requestId: string;
}) {
  const computedPayload = useComputeDataSourcePayload<{
    id: string;
    inputs: QueryInputsType;
  }>({
    data: { id, inputs: query },
  });
  const { setDataSourceState } = useGlobalStateStore().use.actions();
  const getGlobalStateStoreState = useGlobalStateStore().getState;

  const queryResult = useUAQuerySource({ query: computedPayload.inputs.payload });

  useExecuteDataSourceCallbacks({
    callbacks,
    onQueryCompletion,
    id,
    requestId,
    queryResult: {
      error: queryResult.error,
      isLoading: queryResult.isLoading,
      isFetching: queryResult.isFetching,
    },
  });

  useEffect(() => {
    const previousDataSource = getGlobalStateStoreState().dataSources[id];
    if (isEqual(queryResult, previousDataSource)) return;
    setDataSourceState(id, queryResult);
  }, [getGlobalStateStoreState, id, queryResult, setDataSourceState]);

  return null;
}

export default UAQueryNode;
