'use client';

import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { LookupByKeysRequestType } from '@unifyapps/network/generated/models/lookupByKeysRequestType';
import { lookup } from '@unifyapps/network/generated/lookup-rest-api/lookup-rest-api';
import type { Notification as NotificationType } from '@unifyapps/network/generated/models/notification';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';
import type { LookupByKeysRequest } from '@unifyapps/network/generated/models/lookupByKeysRequest';
import type { Event } from '@unifyapps/defs/types/event';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { getAllBlockEvents } from '../../utils/block';
import { useBlocksFromPage } from '../../stores/InterfaceStore';
import useBlockEvents from '../../hooks/useBlockEvents';
import userStore from '../../../auth/userStore';
import { useUserInboxSubscription } from '../../../components/Notifications/hooks/useUserInboxSubscription';
import { safeJsonParse } from '../../../utils/json';
import type { NotificationEventTypesLookupResponse } from '../../../components/Notifications/types';
import { EMPTY_OBJECT } from '../../../consts/empty';

function NotificationsActionsProvider({
  children,
  events,
}: {
  children: ReactNode;
  events: Event[];
}) {
  const userData = userStore.use.currentUserDetails();
  const loggedInUserId = userData?.user?.id;

  const { emitEvent } = useBlockEvents(events);

  const onNotificationReceive = useEventCallback(async (payload: Buffer) => {
    const notificationPayload = safeJsonParse(payload.toString());
    const { notificationEventType } = notificationPayload as NotificationType;

    if (!notificationEventType) {
      console.error('NotificationEventType is not provided in the notification payload');
      return;
    }

    const { response } = (await lookup({
      type: LookupByKeysRequestType.ByKeys,
      lookupType: 'ENTITY',
      keys: [notificationEventType],
      options: {
        entity_type: UAEntityTypes.NotificationEventType,
      },
    } as LookupByKeysRequest)) as NotificationEventTypesLookupResponse;

    void emitEvent({
      eventType: EventTypeEnum.OnReceiveNotification,
      actionContext: {
        notification: response?.objects?.[notificationEventType],
      },
    });
  });

  useUserInboxSubscription({
    loggedInUserId,
    // eslint-disable-next-line @typescript-eslint/no-misused-promises -- not needed
    onNotificationReceive,
  });

  return children;
}

function NotificationsActionsProviderWrapper({ children }: { children: ReactNode }) {
  const userData = userStore.use.currentUserDetails();
  const loggedInUserId = userData?.user?.id;
  const blocks = useBlocksFromPage();

  const allBlockEvents = useMemo(
    () =>
      _filter(getAllBlockEvents(blocks || EMPTY_OBJECT), {
        eventType: EventTypeEnum.OnReceiveNotification,
      }),
    [blocks],
  );

  if (_isEmpty(allBlockEvents) || !loggedInUserId) {
    return children;
  }

  return (
    <NotificationsActionsProvider events={allBlockEvents}>{children}</NotificationsActionsProvider>
  );
}

export default NotificationsActionsProviderWrapper;
