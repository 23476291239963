export const createIdFromQueryKeyPrefix = (params: {
  queryKeyPrefix?: string;
  originalId: string;
}) => {
  return params.queryKeyPrefix ? `${params.queryKeyPrefix}${params.originalId}` : params.originalId;
};

export const getOrginalIdFromQueryKeyPrefix = (params: { queryKeyPrefix?: string; id: string }) => {
  return params.queryKeyPrefix ? params.id.replace(params.queryKeyPrefix, '') : params.id;
};
