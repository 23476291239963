import type { Query } from '@unifyapps/defs/types/query';
import type { QueryRequestResult } from '../../../../stores/GlobalStateStore';
import { OPERATION_ID_TO_LOOKUP_HOOK, OPERATION_ID_TO_RESPONSE_ADAPTER } from '../../utils';

function useUAQuerySource({ query }: { query: Query }): QueryRequestResult {
  const { operationId, ...rest } = query;

  const useLookupQuery = OPERATION_ID_TO_LOOKUP_HOOK[operationId];

  if (!useLookupQuery) {
    throw new Error(`Unsupported operationId: ${operationId}`);
  }

  const {
    isLoading,
    isFetching,
    error: fetchError,
    data,
    queryKey,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- we are assuming the payload of the query is correct
  } = useLookupQuery(rest as any, { query: { forceFetch: true, staleTime: 0, cacheTime: 0 } });

  const { objects } = OPERATION_ID_TO_RESPONSE_ADAPTER[operationId](data);

  return {
    error: fetchError,
    isLoading,
    isFetching,
    data,
    queryKey,
    objects,
  };
}

export default useUAQuerySource;
