import { useCallback } from 'react';
import { invariant } from 'ts-invariant';
import type { OnActionArgs } from '../../../../components/ActionsProvider/context';
import useControlBlockMethod from '../controlBlockMethod/useControlBlockMethod';

type FormActionPayload = {
  formId?: string;
};

function useFormActions() {
  const { onCallBlockMethod } = useControlBlockMethod();

  const onSubmitForm = useCallback(
    async ({ action, actionContext }: OnActionArgs) => {
      const payload = action.payload as FormActionPayload;
      const blockId = payload.formId;
      invariant(blockId, 'Form id is required');
      await onCallBlockMethod({
        blockId,
        methodName: 'submitForm',
        action,
        actionContext,
      });
      return Promise.resolve();
    },
    [onCallBlockMethod],
  );

  return { onSubmitForm };
}

export default useFormActions;
