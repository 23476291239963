import { pick } from 'lodash';
import isEqual from 'react-fast-compare';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-use';
import { useRouter } from '../../../../hooks/useRouter';
import {
  useGetGlobalStateStoreState,
  useGlobalStateStore,
} from '../../../../stores/GlobalStateStore';

function useSetLocationInStore() {
  const { pathParams } = useRouter();
  const location = useLocation();
  const { setLocation } = useGlobalStateStore().use.actions();
  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const pickedLocation = useMemo(
    () => ({
      ...pick(location, ['href', 'hostname', 'pathname', 'search', 'hash', 'origin']),
      pathParams,
    }),
    [location, pathParams],
  );

  useEffect(() => {
    const locationInStore = getGlobalStateStoreState().location;
    if (!isEqual(pickedLocation, locationInStore)) {
      setLocation(pickedLocation);
    }
  }, [getGlobalStateStoreState, pickedLocation, setLocation]);
}

export default useSetLocationInStore;
