import { useRef } from 'react';
import { useReactiveComputeContext } from '../../../../hooks/computeContext';
import { getComputedData } from '../../../../utils/getComputedData';

export function useComputeDataSourcePayload<T>({
  data,
  refetchOnPayloadChange = true,
}: {
  data: Record<string, unknown>;
  refetchOnPayloadChange?: boolean;
}) {
  const { context } = useReactiveComputeContext();
  const computedPayload = useRef<T | null>(null);

  if (refetchOnPayloadChange) {
    return getComputedData<T>(data, context, true);
  }

  if (computedPayload.current === null) {
    computedPayload.current = getComputedData<T>(data, context);
  }

  return computedPayload.current;
}
