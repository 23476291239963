import { useAggregateMetadata } from '@unifyapps/network/generated/aggregation-rest-api/aggregation-rest-api';
import { useGetImportFieldMapping } from '@unifyapps/network/generated/entity-rest-api/entity-rest-api';

export function useMappedFields({
  entityType,
  fileHeaders,
}: {
  entityType: string;
  fileHeaders?: string[];
}) {
  const {
    data: metadata,
    isLoading,
    error,
  } = useAggregateMetadata({
    group: 'ENTITY',
    entityType,
  });

  const {
    data: fieldMappingFromAPI,
    isLoading: isLoadingAPIMappedData,
    error: backendMappedDataFetchError,
  } = useGetImportFieldMapping({ entityType, fileHeaders });

  return {
    fieldMappingFromAPI,
    isLoading: isLoading || isLoadingAPIMappedData,
    error: error ?? backendMappedDataFetchError,
    metadata,
  };
}
