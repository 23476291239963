import { useInterfaceLocalStorage } from '../useInterfaceLocalStorage';

export const getInteractiveModeLocalStorageKey = (interfaceRecordId: string) =>
  `${interfaceRecordId}.interactiveMode`;

export const useInteractiveModeFromLocalStorage = ({
  interfaceRecordId,
  enabled,
}: {
  interfaceRecordId: string;
  enabled?: boolean;
}) => {
  const { value, loading, setValue } = useInterfaceLocalStorage<boolean | undefined>({
    key: getInteractiveModeLocalStorageKey(interfaceRecordId),
    enabled,
  });

  return {
    value,
    loading,
    setValue,
  };
};
