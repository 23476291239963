import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from './useLocalStorage';

export const useEntityLocalStorageValueAndSetter = <T>(
  key: string,
  options?: { onSuccess?: (data: T | undefined) => void },
  enabled = true,
): {
  data: T | undefined;
  loading: boolean;
  setEntityValue: (value: T) => void;
} => {
  const { onSuccess } = options ?? {};

  const [_setItem, getItem] = useLocalStorage<T>(key);

  const [state, setState] = useState<{ data: T | undefined; loading: boolean }>({
    loading: enabled,
    data: undefined,
  });

  const setEntityValue = useCallback(
    (value: T) => {
      setState((prevState) => ({ ...prevState, data: value }));
      _setItem(value);
    },
    [_setItem],
  );

  useEffect(() => {
    const fetchAndSetItem = async () => {
      const item = await getItem();
      setState({
        loading: false,
        data: item,
      });
      onSuccess?.(item);
    };
    if (enabled) {
      void fetchAndSetItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- don't include deps
  }, [enabled]);

  return {
    ...state,
    setEntityValue,
  };
};
