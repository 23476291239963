import { UAApps } from '@unifyapps/defs/types/apps';
import type { ExecuteWorkflowNodeRequest } from '@unifyapps/network/generated/models/executeWorkflowNodeRequest';
import type { DataSourceWorkflowNodeResponse } from '../../components/DataSourceWorkflowNode/useDataSourceWorkflowNode';
import { adaptResponseWithLookupReferences } from './adaptResponseWithLookupReferences';

export const adaptDataSourceWorkflowNode = (
  data?: DataSourceWorkflowNodeResponse,
  lookupRequests?: ExecuteWorkflowNodeRequest['lookupRequests'],
  appName?: UAApps,
) => {
  if (appName !== UAApps.PlatformByUA && data && 'response' in data) {
    return adaptResponseWithLookupReferences(data, lookupRequests);
  }

  return data;
};
