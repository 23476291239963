import { useMemo, useCallback } from 'react';
import { getIsDataSourceAnEntityType } from '../../../../../utils/entityDataSource';
import useGetAutomaticDataSourceIds from '../../../hooks/useGetAutomaticDataSourceIds';
import { useInterfaceStore } from '../../../../../stores/InterfaceStore';
import { DependencyFlowStatus, useGlobalStateStore } from '../../../../../stores/GlobalStateStore';
import { useDataSourceRecords } from '../../../../../stores/DataSourceRecordStore';
import { getLookupRequestsForDataSources } from '../../../utils/getLookupRequestsForDataSources';
import { ApiEndpointQueryNode } from '../../ApiEndpointQueryNode';
import { UAQueryNode } from '../../UAQueryNode';
import ApplicationDataSourceQueryNode from '../../ApplicationDataSourceQueryNode';
import useCommitReadyDataSources from '../../../hooks/useCommitReadyDataSources';
import useFinishDisabledAutomaticDS from '../../../hooks/useFinishDisabledAutomaticDS';
import { useGetEntityTypeDataSources } from '../../../hooks/useGetEntityTypeDataSources';
import type { QueryNode } from '../../../types';

function AutomaticDataSourceController() {
  const dataSources = useDataSourceRecords();
  const { automaticDataSourceIds } = useGetAutomaticDataSourceIds();
  const { disabledDataSources } = useFinishDisabledAutomaticDS(automaticDataSourceIds);

  const getGlobalStateStoreState = useGlobalStateStore().getState;
  const dataSourcesFromActivePage = useInterfaceStore().use.page.dataSources();

  const dependencies = useInterfaceStore().use.page.metadata.dependencies();
  const dependencyById = useInterfaceStore().use.page.metadata.dependencyById();
  const { setDataSourceState, datasource } = useGlobalStateStore().use.actions();

  const lookupRequestsByDataSourceId = useMemo(
    () => getLookupRequestsForDataSources(dependencies, dependencyById),
    [dependencies, dependencyById],
  );

  const { entityTypeDataSources } = useGetEntityTypeDataSources();

  const getDataSource = useCallback(
    (dataSourceId: string) => {
      if (getIsDataSourceAnEntityType(dataSourceId)) {
        return entityTypeDataSources[dataSourceId];
      }
      return dataSources[dataSourceId]?.properties ?? dataSourcesFromActivePage?.[dataSourceId];
    },
    [dataSources, dataSourcesFromActivePage, entityTypeDataSources],
  );

  const getCurrentQueryResult: QueryNode['getCurrentQueryResult'] = useCallback(
    ({ id }) => {
      return getGlobalStateStoreState().dataSources[id];
    },
    [getGlobalStateStoreState],
  );

  const onQueryCompletion: QueryNode['onQueryCompletion'] = useCallback(
    ({ id }) => {
      datasource.addDependencyFlow(id, DependencyFlowStatus.Finished);
    },
    [datasource],
  );

  const onSetDataSourceState: QueryNode['setDataSourceState'] = useCallback(
    ({ id, result }) => {
      setDataSourceState(id, result);
    },
    [setDataSourceState],
  );

  // This hook is responsible for taking current staged data sources to commit stange when its dependent are finished
  useCommitReadyDataSources();

  return (
    <>
      {automaticDataSourceIds.map((id) => {
        const dataSource = getDataSource(id);
        const lookupRequests = lookupRequestsByDataSourceId[id];

        if (!dataSource) return null;

        if (dataSource.type === 'QUERY_BY_UA' && dataSource.inputs) {
          return (
            <UAQueryNode
              callbacks={dataSource.callbacks}
              id={id}
              key={id}
              onQueryCompletion={onQueryCompletion}
              query={dataSource.inputs}
              requestId={id}
            />
          );
        } else if (dataSource.type === 'API_ENDPOINT' && dataSource.inputs) {
          return (
            <ApiEndpointQueryNode
              apiEndpoint={dataSource.inputs}
              callbacks={dataSource.callbacks}
              id={id}
              key={id}
              onQueryCompletion={onQueryCompletion}
              requestId={id}
            />
          );
        } else if (dataSource.type === 'APPLICATION') {
          return (
            <ApplicationDataSourceQueryNode
              dataSource={dataSource}
              disabled={disabledDataSources[id]}
              getCurrentQueryResult={getCurrentQueryResult}
              id={id}
              key={id}
              lookupRequests={lookupRequests}
              onQueryCompletion={onQueryCompletion}
              requestId={id}
              setDataSourceState={onSetDataSourceState}
            />
          );
        }
        return null;
      })}
    </>
  );
}

export default AutomaticDataSourceController;
