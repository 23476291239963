import _map from 'lodash/map';
import React from 'react';
import { useInterfaceStore } from '../../stores/InterfaceStore';
import { PageFunctionExecutor } from './PageFunctionExecutor';

function PageFunctionController({ children }: { children: React.ReactNode }) {
  const pageFunctions = useInterfaceStore().use.page.pageFunctions();
  return (
    <>
      {_map(pageFunctions, (pageFunction) => {
        const { id, functionBody } = pageFunction;
        return <PageFunctionExecutor functionBody={functionBody} id={id} key={id} />;
      })}
      {children}
    </>
  );
}

export default PageFunctionController;
