import { useMemo } from 'react';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import { safeJsonParse } from '../../../utils/json';
import type { InterfaceResourceProviderProps } from '../../components/InterfaceResourceProvider/types';
import { PAGE_INPUT_QUERY_KEY } from '../../const';
import { getDefaultValuesFromSchema } from '../../utils/getDefaultValuesFromSchema';
import { InterfaceModes } from '../../stores/InterfaceStore';
import { useRouter } from '../useRouter';

function useResolvePageInputs({
  mode,
  page,
  pageInputs,
}: {
  pageInputs: InterfaceResourceProviderProps['pageInputs'];
  mode: InterfaceModes;
  page: InterfacePageEntity;
}) {
  const { searchParams } = useRouter();
  const _pageInputs = useMemo(() => {
    const pageInputsFromSearchParams = safeJsonParse(
      searchParams.get(PAGE_INPUT_QUERY_KEY) ?? '{}',
    );
    if (mode === InterfaceModes.RUNNER)
      return {
        ...pageInputsFromSearchParams,
        ...pageInputs,
      };
    const fallbackValues = getDefaultValuesFromSchema(page.properties.inputSchema?.schema ?? {});
    return {
      ...fallbackValues,
      ...pageInputsFromSearchParams,
      ...pageInputs,
    };
  }, [mode, page.properties.inputSchema?.schema, pageInputs, searchParams]);

  return { pageInputs: _pageInputs };
}

export default useResolvePageInputs;
