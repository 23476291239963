import React from 'react';
import { AggregationMetadataHandler } from './components/AggregationMetadataHandler';
import { AutomaticDataSourceController } from './components/controllers/AutomaticDataSourceController';
import { ManualDataSourceController } from './components/controllers/ManualDataSourceController';
import PreviewDataSourceController from './components/PreviewDataSourceHandler/PreviewDataSourceHandler';

function DataSourceController({ children }: { children: React.ReactNode }) {
  return (
    <>
      <AggregationMetadataHandler />
      <AutomaticDataSourceController />
      <ManualDataSourceController />
      <PreviewDataSourceController />
      {children}
    </>
  );
}

export default DataSourceController;
