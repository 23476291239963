import type {
  ComputedPageVariableState,
  SetVariableArrayTypePayload,
} from '@unifyapps/defs/types/pageVariable';
import _slice from 'lodash/slice';
import _isUndefined from 'lodash/isUndefined';
import { EMPTY_ARRAY } from '../../../../../consts/empty';

export function getNextPageVariableStateForArray({
  variableState,
  operationDetails,
}: {
  variableState: ComputedPageVariableState<unknown>;
  operationDetails: {
    operation: SetVariableArrayTypePayload['operationDetails']['operation'];
    value?: unknown;
  };
}) {
  switch (operationDetails.operation) {
    case 'SET': {
      if (!_isUndefined(operationDetails.value) && !Array.isArray(operationDetails.value)) {
        console.error(
          'variableState.value and operationDetails.value should be array for set operation',
        );
        return;
      }
      return {
        ...variableState,
        value: operationDetails.value ?? EMPTY_ARRAY,
      };
    }
    case 'APPEND': {
      if (
        (!_isUndefined(variableState.value) && !Array.isArray(variableState.value)) ||
        (!_isUndefined(operationDetails.value) && !Array.isArray(operationDetails.value))
      ) {
        console.error(
          'variableState.value and operationDetails.value should be array for append operation',
        );
        return;
      }
      return {
        ...variableState,
        value: [
          ...((variableState.value as unknown[] | undefined) ?? EMPTY_ARRAY),
          ...((operationDetails.value as unknown[] | undefined) ?? EMPTY_ARRAY),
        ],
      };
    }
    case 'PREPEND': {
      if (
        (!_isUndefined(variableState.value) && !Array.isArray(variableState.value)) ||
        (!_isUndefined(operationDetails.value) && !Array.isArray(operationDetails.value))
      ) {
        console.error(
          'variableState.value and operationDetails.value should be array for prepend operation',
        );
        return;
      }

      return {
        ...variableState,
        value: [
          ...((operationDetails.value as unknown[] | undefined) ?? EMPTY_ARRAY),
          ...((variableState.value as unknown[] | undefined) ?? EMPTY_ARRAY),
        ],
      };
    }
    case 'POP': {
      return {
        ...variableState,
        value: _slice(variableState.value as unknown[], 0, -1),
      };
    }
    case 'SHIFT': {
      return {
        ...variableState,
        value: _slice(variableState.value as unknown[], 1),
      };
    }
  }
}
