import { useCallback, useMemo } from 'react';
import type { ExecuteWorkflowNodeRequest } from '@unifyapps/network/generated/models/executeWorkflowNodeRequest';
import type { UseQueryOptions } from '@unifyapps/network/react-query';
import { keepPreviousData, useQuery } from '@unifyapps/network/react-query';
import { UAApps } from '@unifyapps/defs/types/apps';
import type { ErrorType } from '@unifyapps/network/utils/fetch';
import { UAResources } from '@unifyapps/defs/types/resources';
import type { QueryRequestResult } from '../../../../stores/GlobalStateStore';
import { adaptDataSourceWorkflowNode } from '../../utils/adaptDataSourceWorkflowNode';
import type { DebugParams } from './api';
import {
  executeAuthWorkflowNode,
  executeMultiPartFormDataWorkflowNode,
  executeUpdatePasswordWorkflowNode,
  executeWorkflowNode,
  useExecuteWorkflowNodeQueryKeyMemo,
  executePlatformByUAOperation,
} from './api';
import useDSWorkflowNodePayload from './useDSWorkflowNodePayload';

type UseDataSourceWorkflowNodeParams = {
  payload: ExecuteWorkflowNodeRequest;
  debugParams: DebugParams;
  additionalParams?: Record<string, unknown>;
  queryOptions?: Partial<
    Pick<UseQueryOptions<unknown, ErrorType<unknown>>, 'refetchOnWindowFocus'>
  >;
  enabled?: boolean;
};

export type DataSourceWorkflowNodeResponse = Awaited<
  ReturnType<typeof executeWorkflowNode> | ReturnType<typeof executePlatformByUAOperation>
>;
export type DataSourceWorkflowNodeError = ErrorType<unknown>;

function useDataSourceWorkflowNode({
  payload,
  debugParams,
  additionalParams,
  queryOptions,
  enabled,
}: UseDataSourceWorkflowNodeParams): QueryRequestResult {
  const transformedPayload = useDSWorkflowNodePayload({
    payload,
  });
  const isFormData = transformedPayload instanceof FormData;

  const appName = payload.context?.appName as UAApps | undefined;

  const isAuthByUnifyApps = appName === UAApps.AuthByUA;
  const isPlatformByUnifyApps = appName === UAApps.PlatformByUA;
  const isUpdatePassword =
    appName === UAApps.StandardEntities &&
    payload.context?.resourceName === UAResources.StandardEntitiesResetPassword;

  const queryKeyType = useMemo(() => {
    if (isFormData) return 'formdata';
    if (isAuthByUnifyApps) return 'auth';
    if (isUpdatePassword) return 'api-update-password';

    return 'api';
  }, [isAuthByUnifyApps, isFormData, isUpdatePassword]);

  const queryKey = useExecuteWorkflowNodeQueryKeyMemo(
    payload,
    debugParams,
    additionalParams,
    queryKeyType,
  );

  const queryFunction = useCallback(() => {
    if (isFormData) return executeMultiPartFormDataWorkflowNode(transformedPayload, debugParams);
    if (isAuthByUnifyApps) return executeAuthWorkflowNode(transformedPayload, debugParams);
    if (isUpdatePassword) return executeUpdatePasswordWorkflowNode(transformedPayload, debugParams);
    if (isPlatformByUnifyApps) {
      return executePlatformByUAOperation(transformedPayload, debugParams);
    }
    return executeWorkflowNode(transformedPayload, debugParams);
  }, [
    debugParams,
    isAuthByUnifyApps,
    isFormData,
    isPlatformByUnifyApps,
    isUpdatePassword,
    transformedPayload,
  ]);

  const {
    isLoading,
    isFetching,
    error: fetchError,
    data: initialData,
    refetch,
  } = useQuery<
    Awaited<
      ReturnType<typeof executeWorkflowNode> | ReturnType<typeof executePlatformByUAOperation>
    >,
    DataSourceWorkflowNodeError,
    DataSourceWorkflowNodeResponse
  >({
    queryFn: queryFunction,
    queryKey,
    enabled,
    placeholderData: keepPreviousData,
    ...queryOptions,
  });

  const data = useMemo(
    () => adaptDataSourceWorkflowNode(initialData, payload.lookupRequests, appName),
    [appName, initialData, payload.lookupRequests],
  );

  return {
    error: fetchError?.payload,
    isLoading,
    isFetching,
    data,
    queryKey,
    refetch,
  };
}

export default useDataSourceWorkflowNode;
