import type { AutomationInputsType, NodeContextType } from '@unifyapps/defs/types/dataSource';
import { useMemo } from 'react';
import { CLIENT_KEY, INTERNALS_PARAMETERS_KEY } from '../../../../const';
import { useInterfaceStore } from '../../../../stores/InterfaceStore';

export function useAddSystemParameters({
  inputs,
  context,
}: {
  inputs: AutomationInputsType;
  context?: NodeContextType;
}) {
  const mode = useInterfaceStore().use.mode();

  return useMemo(() => {
    if (context?.resourceName === 'callables_call_automation') {
      return {
        ...inputs,
        parameters: {
          ...inputs.parameters,
          [INTERNALS_PARAMETERS_KEY]: {
            [CLIENT_KEY]: mode,
          },
        },
      };
    }
    return inputs;
  }, [inputs, context?.resourceName, mode]);
}
