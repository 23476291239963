import type { AggregationField } from '@unifyapps/network/generated/models/aggregationField';
import Stack from '@unifyapps/ui/_components/Stack';
import { Typography } from '@unifyapps/ui/components/Typography';
import { getDataTypeIcon } from '../../../utils/dataType';

function EntityFieldCell({ field }: { field: AggregationField }) {
  const Icon = getDataTypeIcon(field.fieldType);

  return (
    <Stack alignItems="center" className="gap-x-md w-full" direction="row">
      <Icon className="text-fg-quaternary size-4" />
      <Typography color="text-primary" variant="text-sm">
        {field.displayName}
      </Typography>
    </Stack>
  );
}

export default EntityFieldCell;
