import type { ExecuteWorkflowNodeRequest } from '@unifyapps/network/generated/models/executeWorkflowNodeRequest';
import { executeWorkflowNode as dataSourceWorkflow } from '@unifyapps/network/generated/workflow-rest-api/workflow-rest-api';
import {
  useMutation,
  type MutationFunction,
  type UseMutationOptions,
} from '@unifyapps/network/react-query';
import type { ErrorType, executeFetch } from '@unifyapps/network/utils/fetch';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];
type DataSourceWorkflowRequest = ExecuteWorkflowNodeRequest;

export const getDataSourceWorkflowMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dataSourceWorkflow>>,
    TError,
    { data: DataSourceWorkflowRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof dataSourceWorkflow>>,
  TError,
  { data: DataSourceWorkflowRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dataSourceWorkflow>>,
    { data: DataSourceWorkflowRequest }
  > = (props) => {
    const { data } = props ?? {};

    return dataSourceWorkflow(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DataSourceWorkflowMutationResult = NonNullable<
  Awaited<ReturnType<typeof dataSourceWorkflow>>
>;
export type DataSourceWorkflowMutationBody = DataSourceWorkflowRequest;
export type DataSourceWorkflowMutationError = ErrorType<unknown>;

export const useDataSourceWorkflowMutation = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dataSourceWorkflow>>,
    TError,
    { data: DataSourceWorkflowRequest },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}) => {
  const mutationOptions = getDataSourceWorkflowMutationOptions(options);

  return useMutation(mutationOptions);
};
