import { removeDataBindingBraces } from '../../../../utils/dynamicBindings';
import type { OnActionArgs } from '../../../../components/ActionsProvider/context';
import { indirectExecuteScript } from '../../../../utils/indirectEval';

const windowVarsRegex = /\bwindow\.(?<temp1>\w+)\b/g;

function findWindowProps(inputString: string) {
  const matches: string[] = [];

  let match: RegExpExecArray | null;
  while ((match = windowVarsRegex.exec(inputString)) !== null) {
    matches.push(match[1]);
  }

  return matches;
}

export const useExecuteScript = () => {
  const onExecuteScript = ({ action, actionContext }: OnActionArgs) => {
    const script = action.payload?.script as string | undefined;

    try {
      if (script) {
        indirectExecuteScript(
          removeDataBindingBraces(script),
          actionContext,
          findWindowProps(script),
        );
      }
    } catch (err) {
      console.error('Error evaluating script: ', err);
    }

    return Promise.resolve();
  };

  return { onExecuteScript };
};
