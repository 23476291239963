import { useCallback, useMemo } from 'react';
import { InterfaceModes, useInterfaceStore } from '../../../../stores/InterfaceStore';
import { useGlobalStateStore } from '../../../../stores/GlobalStateStore';
import ApplicationDataSourceQueryNode from '../ApplicationDataSourceQueryNode';
import { getLookupRequestsForDataSources } from '../../utils/getLookupRequestsForDataSources';
import useMergedDataSources from '../../hooks/useMergedDataSources';
import type { QueryNode } from '../../types';
import usePreviewDataSourceIds from './usePreviewDataSourceIds';

function PreviewDataSourceController() {
  const { dataSources } = useMergedDataSources();
  const lookupDependencies = useInterfaceStore().use.page.metadata.dependencies();
  const lookupDependencyById = useInterfaceStore().use.page.metadata.dependencyById();
  const getGlobalStateStoreState = useGlobalStateStore().getState;
  const interfaceMode = useInterfaceStore().use.mode();
  const { ids } = usePreviewDataSourceIds();
  const {
    datasource: { previewDataSource },
    setPreviewDataInDSMeta,
  } = useGlobalStateStore().use.actions();

  const onDataSourceCompletion: QueryNode['onQueryCompletion'] = useCallback(
    ({ id }) => {
      previewDataSource(id, false);
    },
    [previewDataSource],
  );

  const lookupRequestsByDataSourceId = useMemo(
    () => getLookupRequestsForDataSources(lookupDependencies, lookupDependencyById),
    [lookupDependencies, lookupDependencyById],
  );

  const getCurrentQueryResult: QueryNode['getCurrentQueryResult'] = useCallback(
    ({ id }) => {
      return getGlobalStateStoreState().dataSourcesMeta[id]?.previewResult;
    },
    [getGlobalStateStoreState],
  );

  const onSetDataSourceState: QueryNode['setDataSourceState'] = useCallback(
    ({ id, result }) => {
      setPreviewDataInDSMeta(id, result);
    },
    [setPreviewDataInDSMeta],
  );

  if (interfaceMode !== InterfaceModes.BUILDER) return null;

  return (
    <>
      {ids.map((dsId) => {
        const dataSource = dataSources[dsId]?.properties;
        const lookupRequests = lookupRequestsByDataSourceId[dsId];

        if (!dataSource) return null;

        if (dataSource.type === 'APPLICATION') {
          return (
            <ApplicationDataSourceQueryNode
              dataSource={dataSource}
              getCurrentQueryResult={getCurrentQueryResult}
              id={dsId}
              key="previewDataSource"
              lookupRequests={lookupRequests}
              onQueryCompletion={onDataSourceCompletion}
              refetchOnPayloadChange={false}
              requestId={dsId}
              setDataSourceState={onSetDataSourceState}
            />
          );
        }
        return null;
      })}
    </>
  );
}

export default PreviewDataSourceController;
