import { useMemo } from 'react';
import type { ApplicationAdvancedOptionsType } from '@unifyapps/defs/types/dataSource';

export type QueryOptions = {
  refetchInterval?: number;
  refetchOnWindowFocus?: boolean;
};

class QueryOptionsBuilder {
  private options: QueryOptions;

  constructor(initialOptions: QueryOptions = {}) {
    this.options = { ...initialOptions };
  }

  withTiming(advancedOptions: ApplicationAdvancedOptionsType | undefined) {
    const timing = advancedOptions?.timing;
    if (timing?.runQueryPeriodically && Number(timing.runQueryPeriodInterval) > 0) {
      this.options.refetchInterval = Number(timing.runQueryPeriodInterval);
    }
    return this;
  }

  withFetchSettings(advancedOptions: ApplicationAdvancedOptionsType | undefined) {
    if (advancedOptions?.refetchOnWindowFocus === false) {
      this.options.refetchOnWindowFocus = false;
    }
    return this;
  }

  build() {
    return this.options;
  }
}

export function useGenerateQueryOptionsFromAdvancedOptions(
  advancedOptions: ApplicationAdvancedOptionsType | undefined,
) {
  return useMemo(() => {
    const queryOptionsBuilder = new QueryOptionsBuilder();
    queryOptionsBuilder.withTiming(advancedOptions);
    queryOptionsBuilder.withFetchSettings(advancedOptions);

    return queryOptionsBuilder.build();
  }, [advancedOptions]);
}
