import type { EmitEventType } from '@unifyapps/defs/hooks/useBlockEvents';
import useGlobalBlockActions from '../../hooks/useGlobalBlockActions';
import InterfaceEntitiesMethodsProvider from './InterfaceEntitiesMethodsProvider';

function InterfaceEntitiesMethodsProviderWrapper({
  emitPageEvent,
  children,
}: {
  emitPageEvent: EmitEventType | undefined;
  children: React.ReactNode;
}) {
  const { onAction } = useGlobalBlockActions({
    emitPageEvent,
  });

  return (
    <InterfaceEntitiesMethodsProvider onAction={onAction}>
      {children}
    </InterfaceEntitiesMethodsProvider>
  );
}
export default InterfaceEntitiesMethodsProviderWrapper;
