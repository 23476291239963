import type {
  ComputedPageVariableState,
  SetVariableStringTypePayload,
} from '@unifyapps/defs/types/pageVariable';
import _isUndefined from 'lodash/isUndefined';

export function getNextPageVariableStateForString({
  variableState,
  operationDetails,
}: {
  variableState: ComputedPageVariableState<unknown>;
  operationDetails: {
    operation: SetVariableStringTypePayload['operationDetails']['operation'];
    value?: unknown;
  };
}) {
  switch (operationDetails.operation) {
    case 'SET': {
      if (!_isUndefined(operationDetails.value) && typeof operationDetails.value !== 'string') {
        console.error('operationDetails.value should be string for set operation');
      }
      return {
        ...variableState,
        value: operationDetails.value ?? '',
      };
    }
    case 'APPEND': {
      if (
        (!_isUndefined(variableState.value) && typeof variableState.value !== 'string') ||
        (!_isUndefined(operationDetails.value) && typeof operationDetails.value !== 'string')
      ) {
        console.error(
          'variableState.value and operationDetails.value should be string for append operation',
        );
      }

      return {
        ...variableState,
        value: `${(variableState.value as string | undefined) ?? ''}${(operationDetails.value as string | undefined) ?? ''}`,
      };
    }
    case 'PREPEND': {
      if (
        (!_isUndefined(variableState.value) && typeof variableState.value !== 'string') ||
        (!_isUndefined(operationDetails.value) && typeof operationDetails.value !== 'string')
      ) {
        console.error(
          'variableState.value and operationDetails.value should be string for prepend operation',
        );
      }
      return {
        ...variableState,
        value: `${(operationDetails.value as string | undefined) ?? ''}${(variableState.value as string | undefined) ?? ''}`,
      };
    }
  }
}
