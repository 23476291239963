import { useCallback } from 'react';
import { createQueryParams } from '../../../../utils/createQueryParams';
import { PAGE_INPUT_QUERY_KEY, interfacePatterns } from '../../../../const';
import { InterfaceModes, useInterfaceStore } from '../../../../stores/InterfaceStore';
import { getComputedData } from '../../../../utils/getComputedData';
import { useRouter } from '../../../useRouter';
import type { OnActionArgs } from '../../../../components/ActionsProvider/context';
import {
  getInterfacePageBuilderRoute,
  getInterfacePageNavigationBuilderURL,
  getInterfacePageNavigationPreviewURL,
  getInterfacePreviewRoute,
} from '../../../../utils/interfaceRoutes';

type NavigateToPagePayloadType = {
  pageId?: string;
  pageInputs?: Record<string, unknown>;
};

const getUrl = ({
  interfacePageSlug,
  interfaceRecordId,
  isPublic,
  mode,
}: {
  interfacePageSlug: string;
  interfaceRecordId: string;
  mode: InterfaceModes;
  isPublic?: boolean;
}) => {
  const isBuilderMode = interfacePatterns.isBuilderMode;
  switch (mode) {
    case InterfaceModes.RUNNER:
      if (isPublic) {
        return `/public/${interfacePageSlug}`;
      }
      return `/${interfacePageSlug}`;
    case InterfaceModes.BUILDER:
      return getInterfacePageBuilderRoute(interfaceRecordId, interfacePageSlug);

    case InterfaceModes.PREVIEW: {
      const pathname = typeof window !== 'undefined' ? window.location.pathname : '';
      if (isBuilderMode.test(pathname)) {
        return getInterfacePageBuilderRoute(interfaceRecordId, interfacePageSlug);
      }
      return getInterfacePreviewRoute({
        interfaceId: interfaceRecordId,
        interfacePageSlug,
      });
    }
    default:
      return null;
  }
};

const getLegacyUrl = ({
  interfacePageId,
  interfaceRecordId,
  mode,
  isPublic,
}: {
  interfacePageId: string;
  interfaceRecordId: string;
  mode: InterfaceModes;
  isPublic?: boolean;
}) => {
  const isBuilderMode = interfacePatterns.isBuilderMode;
  switch (mode) {
    case InterfaceModes.RUNNER:
      if (isPublic) {
        return `/public/${interfacePageId}`;
      }
      return `/${interfacePageId}`;
    case InterfaceModes.BUILDER:
      return getInterfacePageNavigationBuilderURL(interfaceRecordId, interfacePageId);
    case InterfaceModes.PREVIEW: {
      const pathname = typeof window !== 'undefined' ? window.location.pathname : '';
      if (isBuilderMode.test(pathname)) {
        return getInterfacePageNavigationBuilderURL(interfaceRecordId, interfacePageId);
      }
      return getInterfacePageNavigationPreviewURL(interfaceRecordId, interfacePageId);
    }
    default:
      return null;
  }
};

export default function useNavigateToPage() {
  const router = useRouter();
  const mode = useInterfaceStore().use.mode();
  const basePath = useInterfaceStore().use.basePath();
  const isPublic = useInterfaceStore().use.isPublic();
  const interfaceRecordId = useInterfaceStore().use.record.id();
  const interfacePageIdVsSlugMap = useInterfaceStore().use.record.interfacePageIdVsSlugMap();

  const navigateToPage = useCallback(
    ({ action, actionContext, domEvent }: OnActionArgs) => {
      const payload = (action.payload ?? {}) as NavigateToPagePayloadType;
      const { pageInputs, pageId: interfacePageId } = getComputedData<NavigateToPagePayloadType>(
        payload,
        actionContext,
      );

      if (!interfacePageId || !interfaceRecordId) {
        return Promise.resolve();
      }

      const interfacePageSlug = interfacePageIdVsSlugMap?.[interfacePageId];

      const path = interfacePageSlug
        ? getUrl({ interfacePageSlug, interfaceRecordId, mode, isPublic })
        : getLegacyUrl({ interfacePageId, interfaceRecordId, mode, isPublic });

      if (path) {
        const query = pageInputs ? createQueryParams(PAGE_INPUT_QUERY_KEY, pageInputs) : '';
        domEvent?.stopPropagation();
        const finalPath = basePath ? `${basePath}${path}` : path;
        router.push(`${finalPath}?${query}`);
      }
      return Promise.resolve();
    },
    [interfaceRecordId, interfacePageIdVsSlugMap, mode, isPublic, basePath, router],
  );

  return { navigateToPage };
}
