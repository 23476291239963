import get from 'lodash/get';
import type { ExecuteWorkflowNodeResponseLookupReferences } from '@unifyapps/network/generated/models/executeWorkflowNodeResponseLookupReferences';

const listWildcard = '[*]';

interface Params {
  data: Record<string, unknown>;
  path: string;
  name: string;
  lookupReferences: ExecuteWorkflowNodeResponseLookupReferences;
}

export const mutateDataWithLookupReferences = (params: Params) => {
  const { data, path, name, lookupReferences } = params;

  const parentPath = path.split('.').slice(0, -1).join('.');
  const parentObject = parentPath ? (get(data, parentPath) as Record<string, unknown>) : data;

  // if value is list - then path will be like a.b.c[*], so we need to remove [*] from path
  // tbd - can there be nested lists? to be handled as per use case
  const value = get(data, path.replace(listWildcard, '')) as undefined | string | string[];

  if (typeof parentObject !== 'object' || !value) {
    return;
  }

  let lookupValue: unknown;

  if (Array.isArray(value)) {
    lookupValue = value.map((item) => (get(lookupReferences, [name, item]) as unknown) ?? item);
  } else {
    lookupValue = get(lookupReferences, [name, value]);
  }

  if (lookupValue) {
    parentObject[name] = lookupValue;
  }
};
